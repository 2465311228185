import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { Examinee } from "../../../api/examineesApi";
import { examineesURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const fetchRecentExaminees = createAsyncThunk<
  Examinee[],
  undefined,
  AsyncThunkConfig
>("users/examinees/recent/get", async (_, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<Examinee[]>>(
      `${examineesURL}?offset=0&limit=10&ordering=-date_created`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
