import { createSlice } from "@reduxjs/toolkit";
import { Examinee } from "../../api/examineesApi";
import { handlePending, handleReject } from "../../utils/redux";
import { fetchExaminees } from "../actions/searchedExamineeResultsActions";
import { BaseApiState } from "../../api/axiosConfig";

export interface SearchedExamineeResultsState extends BaseApiState {
  examinees: Examinee[];
  filter?: string;
}

const initialState: SearchedExamineeResultsState = {
  examinees: [],
  filter: undefined,
  loading: false,
  succeeded: true,
  error: false,
  errorMessage: "",
};
const SearchedExamineeResultsSlice = createSlice({
  name: "SearchedExamineeResultsSlice",
  initialState,
  reducers: {
    clearSearchedExaminees(state) {
      state.examinees = [];
    },
    clearSearchBarFilter(state) {
      state.filter = undefined;
    },
    setSearchBarFilter(state, action) {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExaminees.fulfilled, (state, action) => {
      state.examinees = action.payload.results;
      state.loading = false;
      state.succeeded = true;
    });
    builder.addCase(fetchExaminees.pending, handlePending);
    builder.addCase(fetchExaminees.rejected, handleReject);
  },
});

export const searchedExamineeResultsReducer =
  SearchedExamineeResultsSlice.reducer;

export const {
  clearSearchedExaminees,
  setSearchBarFilter,
  clearSearchBarFilter,
} = SearchedExamineeResultsSlice.actions;
