import {
  AllJudgments,
  CivilExplosivesJudgmentData,
  ProfessionalDriverJudgmentData,
} from "./types";
import dayjs from "dayjs";

export const commonJudgmentDeafults = (judgment: AllJudgments) => {
  return {
    firstName: judgment?.firstName || "",
    lastName: judgment?.lastName || "",
    pesel: judgment?.pesel || "",
    documentType: judgment?.documentType || "",
    documentNumber: judgment?.documentNumber || "",
    country: judgment?.country || "",
    address: judgment?.address || "",
    city: judgment?.city || "",
    postalCode: judgment?.postalCode || "",
    issueDate: judgment?.issueDate || judgment?.completionDate,
    completionDate: judgment?.completionDate || dayjs().toISOString(),
    sex: judgment?.sex || "",
  };
};

export const hasNextExaminationField = (
  obj: AllJudgments
): obj is ProfessionalDriverJudgmentData => {
  if (Object.prototype.hasOwnProperty.call(obj, "nextExamination")) {
    return true;
  }

  return false;
};

export const hasAppealDeadlineField = (
  obj: AllJudgments
): obj is CivilExplosivesJudgmentData => {
  if (Object.prototype.hasOwnProperty.call(obj, "appealDeadline")) {
    return true;
  }

  return false;
};
