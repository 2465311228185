import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
    ApiError,
    ApiResponse,
    AsyncThunkConfig,
    isAxiosError,
} from "../../../api/axiosConfig";
import { PsychUserData } from "../../../api/psychologicalLabApi";
import { psychologicalUserURL } from "../../../utils/paths";
import { dispatchNotification, getTranslatedBackendMessage } from "../../../utils/redux";
import { CreatePsychUserData, EditPsychLabUserDataRequest } from "../../../components/Forms/types";
import { I18n } from "react-redux-i18n";

export const createPsychUser = createAsyncThunk<
    PsychUserData,
    CreatePsychUserData,
    AsyncThunkConfig
>("psychologicalUserCRUD/createPsychUser", async (data, thunkAPI,) => {
    try {
        const response = await client.post(`${psychologicalUserURL}`, data);

        dispatchNotification("success", I18n.t("Snackbars.createPsychUserSuccess"));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            if (extractedError.details && extractedError.details.email && extractedError.details.email.length > 0) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                dispatchNotification("error", I18n.t(`Forms.adminSnackbar.${extractedError.details.email[0]}`));
            } else {
                dispatchNotification("error", extractedError.message, true);
            }

            return thunkAPI.rejectWithValue(extractedError);
        }
    }
});

export const deletePsychUser = createAsyncThunk<
    undefined,
    number,
    AsyncThunkConfig
>("psychologicalUserCRUD/deletePsychUser", async (arg, thunkAPI) => {
    try {
        await client.delete(`${psychologicalUserURL}${arg}/`);
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification(
                "error",
                getTranslatedBackendMessage(extractedError.message)
            );

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});


export const editPsychUser = createAsyncThunk<
    PsychUserData,
    EditPsychLabUserDataRequest,
    AsyncThunkConfig
>("psychologicalUserCRUD/editPsychUser", async (data, thunkAPI,) => {
    try {
        const response = await client.patch<ApiResponse<PsychUserData>>(
            `${psychologicalUserURL}${data.id}/`,
            { ...data }
        );

        dispatchNotification("success", I18n.t("Snackbars.editPsychUserSuccess"));

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            if (extractedError.details && extractedError.details.email && extractedError.details.email.length > 0) {
                let errDetail = "";
                switch (extractedError.details.email[0]) {
                    case "Enter a valid email address.":
                        errDetail = "email_wrong_error";
                        break;
                    case "The user with the provided email address already exists":
                        errDetail = "email_unique_error";
                        break;
                    case "email_unique_error":
                        errDetail = "email_unique_error";
                        break;
                }
                dispatchNotification("error", I18n.t(`Forms.adminSnackbar.${errDetail}`));
            } else {
                dispatchNotification("error", extractedError.message, true);
            }

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});
