import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  ApiResponsePagination,
  AsyncThunkConfig,
  isAxiosError,
} from "../../api/axiosConfig";
import {
  directoriesURL,
  examineesURL,
  moveExamineesURL,
} from "../../utils/paths";
import {
  dispatchNotification,
  getTranslatedBackendMessage,
} from "../../utils/redux";
import {
  ChangeExamineeDirectoryRequestArgs,
  Directory,
  DirectoryContentRequestArgs,
  DirectoryCreateApi,
  DirectoryEditApi,
  DirectoryRequestArgs,
} from "../../api/directoriesApi";
import { Examinee } from "../../api/examineesApi";
import instance from "../../api/axiosConfig";
import { I18n } from "react-redux-i18n";

export const getDirectories = createAsyncThunk<
  ApiResponsePagination<Directory[]>,
  DirectoryRequestArgs,
  AsyncThunkConfig
>("user/directories/get", async (args, thunkAPI) => {
  try {
    const response = await client.request<ApiResponsePagination<Directory[]>>({
      url: directoriesURL,
      params: { limit: args.limit, offset: args.limit * (args.curPage - 1) },
    });

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const deleteDirectory = createAsyncThunk<void, number, AsyncThunkConfig>(
  "user/directories/delete",
  async (id, thunkAPI) => {
    try {
      await client.delete(`${directoriesURL}${id}/`);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification(
          "error",
          getTranslatedBackendMessage(extractedError.message)
        );

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);

export const editDirectoryName = createAsyncThunk<
  void,
  DirectoryEditApi,
  AsyncThunkConfig
>("user/directories/patch", async (directory, thunkAPI) => {
  try {
    await client.patch(`${directoriesURL}${directory.id}/`, {
      name: directory.newName,
    });
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const addDirectory = createAsyncThunk<
  void,
  DirectoryCreateApi,
  AsyncThunkConfig
>("user/directories/add", async (directory, thunkAPI) => {
  try {
    await client.post(`${directoriesURL}`, directory);
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const getSelectedDirectoryDetails = createAsyncThunk<
  ApiResponse<Directory>,
  number,
  AsyncThunkConfig
>("user/directory/get", async (directoryId, thunkAPI) => {
  try {
    const response = await client.request<ApiResponse<Directory>>({
      url: `${directoriesURL}${directoryId}/`,
    });

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const getDirectoryContent = createAsyncThunk<
  ApiResponsePagination<Examinee[]>,
  DirectoryContentRequestArgs,
  AsyncThunkConfig
>("user/examinees/get", async (args, thunkAPI) => {
  try {
    const response = await client.request<ApiResponsePagination<Examinee[]>>({
      url: examineesURL,
      params: {
        limit: args.limit,
        offset: args.limit * (args.curPage - 1),
        // eslint-disable-next-line camelcase
        directory_id: args.directoryId,
      },
    });

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const deleteExaminee = createAsyncThunk<
  undefined,
  number,
  AsyncThunkConfig
>("user/examinees/delete", async (arg, thunkAPI) => {
  try {
    await client.delete(`${examineesURL}${arg}/`);
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification(
        "error",
        getTranslatedBackendMessage(extractedError.message)
      );

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const getAllDirectories = createAsyncThunk<
  ApiResponse<Directory[]>,
  void,
  AsyncThunkConfig
>("user/allDirectories/get", async (args, thunkAPI) => {
  try {
    const response = await client.request<ApiResponsePagination<Directory[]>>({
      url: directoriesURL,
    });

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const changeExamineeDirectory = createAsyncThunk<
  Examinee,
  ChangeExamineeDirectoryRequestArgs,
  AsyncThunkConfig
>("examinee/changeDirectory", async (data, thunkAPI) => {
  try {
    const response = await instance.post<ApiResponse<Examinee>>(
      moveExamineesURL,
      {
        directoryId: data.newDirectoryId,
        examineeIds: data.examineeIds,
      }
    );

    dispatchNotification("success", I18n.t("Snackbars.movingExamineesSuccess"));

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
