import { PsychUserData } from "../../../api/psychologicalLabApi";
import { createSlice } from "@reduxjs/toolkit";
import { fetchSelectedPsychUserData } from "../../actions/adminPanel/selectedPsychUserActions";
import { BaseApiState } from "../../../api/axiosConfig";

export interface SelectedPsychUserState extends BaseApiState {
    selectedPsychUserId?: number;
    selectedPsychUser?: PsychUserData;
    refreshNeeded: boolean;
    loadingPsychUserData: boolean;
    loadingPsychUser: boolean;
}

const selectedPsychUserInitialState: SelectedPsychUserState = {
    selectedPsychUserId: undefined,
    selectedPsychUser: undefined,
    refreshNeeded: false,
    succeeded: false,
    loading: false,
    loadingPsychUserData: false,
    error: false,
    errorMessage: undefined,
    errorDetails: undefined,
    loadingPsychUser: false,
};

const SelectedPsychUserSlice = createSlice({
    name: "selectedPsychUser",
    initialState: selectedPsychUserInitialState,
    reducers: {
        setSelectedPsychUserId(state, action) {
            state.selectedPsychUserId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSelectedPsychUserData.pending, (state) => {
                state.loadingPsychUserData = true;
            })
            .addCase(fetchSelectedPsychUserData.fulfilled, (state, action) => {
                state.selectedPsychUser = action.payload;
                state.loadingPsychUserData = false;
                state.succeeded = true;
                state.refreshNeeded = false;
            })
            .addCase(fetchSelectedPsychUserData.rejected, (state) => {
                state.loadingPsychUserData = false;
                state.error = true;
                state.errorMessage = undefined;
                state.errorDetails = undefined;
            })
    },
});

export const selectedPsychUserReducer = SelectedPsychUserSlice.reducer;
export const { setSelectedPsychUserId } = SelectedPsychUserSlice.actions;
