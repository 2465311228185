import { configureStore } from "@reduxjs/toolkit";
import {
  syncTranslationWithStore,
  loadTranslations,
  setLocale,
} from "react-redux-i18n";
import translations from "../l10n/translations";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
});

// initialize translations
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("pl"));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
