import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { PsychLabActivity, PsychLabUser, PsychologicalLab } from "../../../api/psychologicalLabApi";
import { dispatchNotification } from "../../../utils/redux";
import { psychologicalLabsActivityURL, psychologicalLabsURL, psychologicalLabsUserURL } from "../../../utils/paths";
import { FilterPsychLabActivitiesData } from "../../../components/Forms/types";

export const fetchSelectedPsychLabData = createAsyncThunk<
  PsychologicalLab,
  number,
  AsyncThunkConfig
>("selectedPsychLab/fetchSelectedPsychLabData/get", async (psychLabId, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychologicalLab>>(
      `${psychologicalLabsURL}${psychLabId}/`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchUsersList = createAsyncThunk<
  PsychLabUser[],
  number,
  AsyncThunkConfig
>("selectedPsychLab/fetchUsersList", async (psychLabId, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychLabUser[]>>(
      psychologicalLabsUserURL, { params: { psychLabId } }
    );
    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchActivityList = createAsyncThunk<
  PsychLabActivity[],
  FilterPsychLabActivitiesData,
  AsyncThunkConfig
>("selectedPsychLab/fetchActivityList", async (psychLabFilters, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychLabActivity[]>>(
      psychologicalLabsActivityURL, { data: { psychLabFilters } }
    );
    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});