import { normSetURL } from "../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { dispatchNotification } from "../../../utils/redux";
import { NormSet, ProtocolItem } from "../../../api/documentationApi";

export const fetchNormSets = createAsyncThunk<
  ApiResponse<NormSet[]>,
  undefined,
  AsyncThunkConfig
>("documentation/normSet/fetchAll", async (data, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<ProtocolItem[]>>(
      normSetURL
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
