import { createAsyncThunk } from "@reduxjs/toolkit";
import { ParsedUrlQuery, stringify } from "querystring";
import { I18n } from "react-redux-i18n";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import {
  Examination,
  ExaminationAssignmentParams,
  LegalBasis,
  PsychologicalTestFetchingResponse,
} from "../../../api/examinationApi";
import { Examinee } from "../../../api/examineesApi";
import {
  examinationURL,
  examineesURL,
  legalBasisURL,
  licensedMethodologiesURL,
  psychologicalTestsURL,
} from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const getSelectedExamineeDetails = createAsyncThunk<
  ApiResponse<Examinee>,
  number,
  AsyncThunkConfig
>("user/examinees/get", async (examineeId, thunkAPI) => {
  try {
    const response = await instance.request<ApiResponse<Examinee>>({
      url: `${examineesURL}${examineeId}/`,
    });

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchLegalBases = createAsyncThunk<
  LegalBasis[],
  undefined,
  AsyncThunkConfig
>("examination/legal_bases/get", async (_, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<LegalBasis[]>>(
      legalBasisURL
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchLicensedMethodologies = createAsyncThunk<
  number[],
  undefined,
  AsyncThunkConfig
>("examination/licensed_methodologies/", async (_, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<number[]>>(
      licensedMethodologiesURL
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchPsychologicalTests = createAsyncThunk<
  PsychologicalTestFetchingResponse,
  number[],
  AsyncThunkConfig
>("examination/psychological_tests/get", async (legalBasis, thunkAPI) => {
  try {
    const response = await instance.get<
      ApiResponse<PsychologicalTestFetchingResponse>
    >(psychologicalTestsURL, {
      // eslint-disable-next-line camelcase
      params: { legal_basis: legalBasis },
      paramsSerializer: (params: ParsedUrlQuery) => stringify(params),
    });

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const assignExamination = createAsyncThunk<
  Examination,
  ExaminationAssignmentParams,
  AsyncThunkConfig
>("examination/create", async (data, thunkAPI) => {
  try {
    const response = await instance.post<ApiResponse<Examination>>(
      examinationURL,
      data
    );

    dispatchNotification(
      "success",
      I18n.t("Snackbars.createExaminationSuccess")
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
