import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { DrivingLicense } from "../../../api/examineesApi";
import { drivingLicenseURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const fetchDrivingLicenseCategories = createAsyncThunk<
  DrivingLicense[],
  undefined,
  AsyncThunkConfig
>("users/driving-license-categories/get", async (_, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<DrivingLicense[]>>(
      drivingLicenseURL
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
