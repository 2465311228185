import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { ApiError, BaseApiState } from "../api/axiosConfig";
import { dispatchEnqueueSnackbar } from "../redux/actions/notificationsActions";
import { I18n } from "react-redux-i18n";

interface PayloadActionMeta<T> {
  arg: T;
  requestId: string;
  rejectedWithValue: boolean;
  requestStatus: "rejected";
  aborted: boolean;
  condition: boolean;
}

export type ErrorPayloadAction<T> = PayloadAction<
  ApiError | undefined,
  string,
  PayloadActionMeta<T>,
  SerializedError
>;

export const handleReject = <T>(
  state: WritableDraft<BaseApiState>,
  action: ErrorPayloadAction<T>
) => {
  state.loading = false;
  state.succeeded = false;

  if (action.payload) {
    try {
      const obj = JSON.parse(action.payload.details.message)
      state.expiredUserId = obj.id;
    }
    catch {
      state.expiredUserId = "";
    }

    state.errorDetails = action.payload.details;
    state.error = action.payload.error;
    state.errorMessage = action.payload.message;
  } else {
    state.error = true;
    state.errorMessage = undefined;
    state.errorDetails = undefined;
  }
};

export const clearErrors = (state: WritableDraft<BaseApiState>) => {
  state.error = false;
  state.errorMessage = undefined;
  state.errorDetails = undefined;
};

export const handlePending = (state: WritableDraft<BaseApiState>) => {
  state.loading = true;
  state.succeeded = false;
  clearErrors(state);
};

export type UniversalSnackbarVariants =
  | "default"
  | "success"
  | "error"
  | "warning"
  | "info";

export const getNotificationMessage = (
  variant: UniversalSnackbarVariants,
  message?: string,
  getTranslatedFromBackend?: boolean
) => {
  if (getTranslatedFromBackend && message) {
    switch (variant) {
    case "error":
      return `${getTranslatedBackendMessage(message)}`;
    case "success":
      return getTranslatedBackendMessage(message);
    }
  }

  if (!getTranslatedFromBackend && message) {
    return message;
  }

  if (!getTranslatedFromBackend && !message) {
    switch (variant) {
    case "success":
      return I18n.t("Snackbars.success");
    case "error":
      return `${I18n.t("Snackbars.failedRequest")} ${I18n.t(
        "Snackbars.unrecognizedError"
      )}`;
    }
  }
};

export const dispatchNotification = (
  variant: UniversalSnackbarVariants,
  message?: string,
  getTranslatedFromBackend?: boolean
) => {
  dispatchEnqueueSnackbar({
    message: getNotificationMessage(variant, message, getTranslatedFromBackend),
    options: { variant },
  });
};

export const reformatMessageToKey = (backendMessage: string): string => {
  return backendMessage
    .replaceAll(" ", "_")
    .replaceAll(".", "")
    .replaceAll("'", "");
};

export const getTranslatedBackendMessage = (backendMessage: string) => {
  const formattedBackendMessageKey = reformatMessageToKey(backendMessage);

  return I18n.t(`Backend.${formattedBackendMessageKey}`);
};
