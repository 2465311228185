import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  AsyncThunkConfig,
  isAxiosError,
  ApiError,
  ApiResponse,
} from "../../api/axiosConfig";
import { loginAdminURL, loginURL, loginVerifyURL, logoutURL, updateAdminLabURL } from "../../utils/auth";
import { UpdateAdminLabIdApi, User } from "../../api/userApi";
import { dispatchNotification } from "../../utils/redux";
import {
  getResetPasswordEmailURL,
  resetPasswordSendRequestURL,
} from "../../utils/paths";
import { Nullable } from "../../utils/types";
import store from "../store";
import { clearAuthState } from "../reducers/authReducers";

export const logout = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  "user/logout",
  async (arg_, thunkAPI) => {
    try {
      await client.post(logoutURL);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        return thunkAPI.rejectWithValue(err.response.data as ApiError);
      }

      throw err;
    }
  }
);

export const logoutAdmin = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  "user/logout-admin",
  async (arg_, thunkAPI) => {
    try {
      await client.post(logoutURL);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        return thunkAPI.rejectWithValue(err.response.data as ApiError);
      }

      throw err;
    }
  }
);

type getResetPasswordEmailArgs = {
  email: string;
};

export const getResetPasswordEmail = createAsyncThunk<undefined,
  getResetPasswordEmailArgs,
  AsyncThunkConfig>("getResetPasswordEmail", async (data, thunkAPI) => {
    try {
      await client.post(getResetPasswordEmailURL, data);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message, true);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  });

export const resetPasswordResetSuccess = createAction(
  "resetPasswordResetSuccess"
);

type resetPasswordArgs = {
  userId: string | null;
  token: string;
  newPassword: string;
};

export const resetPasswordSendRequest = createAsyncThunk<undefined,
  resetPasswordArgs,
  AsyncThunkConfig>("resetPasswordSendRequest", async (data, thunkAPI) => {
    try {
      await client.post(resetPasswordSendRequestURL, data);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message, true);

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw err;
    }
  });

type loginArgs = {
  email: string;
  password: string;
  captchaToken?: Nullable<string>;
};

type loginResponse = {
  user: User;
  theme: string;
};

export const login = createAsyncThunk<loginResponse,
  loginArgs,
  AsyncThunkConfig>("login", async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<loginResponse>>(
        loginURL,
        data
      );

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw error;
    }
  });

export const loginAdmin = createAsyncThunk<loginResponse,
  loginArgs,
  AsyncThunkConfig>("loginAdmin", async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<loginResponse>>(
        loginAdminURL,
        data
      );

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw error;
    }
  });

export const verifyLogin = createAsyncThunk<User, void, AsyncThunkConfig>(
  "loginVerify",
  async (data, thunkAPI) => {
    try {
      const response = await client.get<ApiResponse<User>>(loginVerifyURL);

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        return thunkAPI.rejectWithValue(error.response.data as ApiError);
      }
      throw error;
    }
  }
);

export const updateAdminLab = createAsyncThunk<boolean, UpdateAdminLabIdApi, AsyncThunkConfig>(
  "updateAdminLabId",
  async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<boolean>>(updateAdminLabURL, data);

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        return thunkAPI.rejectWithValue(error.response.data as ApiError);
      }
      throw error;
    }
  }
);

export const clearAuthenticationState = () => {
  const { dispatch } = store;

  dispatch(clearAuthState());
};

