import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, {
  AsyncThunkConfig,
  ApiResponse,
  isAxiosError,
  ApiError,
} from "../../../api/axiosConfig";
import { DocumentationItem } from "../../../api/documentationApi";
import { Examination } from "../../../api/examinationApi";
import { examinationURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const fetchExaminationDocumentation = createAsyncThunk<
  DocumentationItem[],
  string,
  AsyncThunkConfig
>("examination/documentation/get", async (examinationId, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<DocumentationItem[]>>(
      `${examinationURL}${examinationId}/documents/`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchExaminationDetails = createAsyncThunk<
  Examination,
  string,
  AsyncThunkConfig
>("examination/get", async (examinationId: string, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<Examination>>(
      `${examinationURL}${examinationId}/`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
