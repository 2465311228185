import { examinationURL } from "../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { dispatchNotification } from "../../../utils/redux";
import { DocumentParams } from "../../../api/documentationApi";
import {
  ExaminationCard,
  ExaminationCardSubmitData,
} from "../../../api/documents/examinationCardApi";
import { formatSubmitData } from "../../../utils/examinationCard";
import { RootState } from "../../store";
import { selectLanguage } from "../../selectors/i18nSelectors";

export const fetchExaminationCard = createAsyncThunk<
  ApiResponse<ExaminationCard>,
  DocumentParams,
  AsyncThunkConfig
>("documentation/examinationCard/get", async (data, thunkAPI) => {
  const languageCode = selectLanguage(thunkAPI.getState() as RootState);

  try {
    const response = await instance.get<ApiResponse<ExaminationCard>>(
      `${examinationURL}${data.examinationId}/documents/${data.documentId}/`,
      { headers: { "Accept-Language": languageCode } }
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const submitExaminationCard = createAsyncThunk<
  ApiResponse<ExaminationCard>,
  ExaminationCardSubmitData,
  AsyncThunkConfig
>(
  "documentation/examinationCard/patch",
  async ({ examinationId, documentId, data }, thunkAPI) => {
    const languageCode = selectLanguage(thunkAPI.getState() as RootState);

    try {
      const response = await instance.patch<ApiResponse<ExaminationCard>>(
        `${examinationURL}${examinationId}/documents/${documentId}/`,
        formatSubmitData(data),
        { headers: { "Accept-Language": languageCode } }
      );

      return response.data;
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message, true);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);

export const updateExaminationCardWithDefaultExaminationCourse =
  createAsyncThunk<
    ApiResponse<ExaminationCard>,
    ExaminationCardSubmitData,
    AsyncThunkConfig
  >(
    "documentation/examinationCard/getWithUpdatedDefaults",
    async ({ examinationId, documentId, data }, thunkAPI) => {
      const languageCode = selectLanguage(thunkAPI.getState() as RootState);

      try {
        const response = await instance.post<ApiResponse<ExaminationCard>>(
          `${examinationURL}${examinationId}/documents/${documentId}/with_default_course_of_examination/`,
          formatSubmitData(data),
          { headers: { "Accept-Language": languageCode } }
        );

        return response.data;
      } catch (err) {
        if (isAxiosError(err) && err.response != null) {
          const extractedError = err.response.data as ApiError;

          dispatchNotification("error", extractedError.message, true);

          return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
      }
    }
  );
