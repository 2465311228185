import { createSlice } from "@reduxjs/toolkit";
import { DrivingLicense } from "../../../api/examineesApi";
import { InputOptions } from "../../../components/Forms/types";
import { fetchDrivingLicenseCategories } from "../../actions/examinees/drivinglicenseActions";

interface DrivingLicenseState {
  drivingLicenseCategories: InputOptions[];
}

const initialState: DrivingLicenseState = {
  drivingLicenseCategories: [],
};

const DrivingLicenseSlice = createSlice({
  name: "dirivingLicenseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDrivingLicenseCategories.fulfilled,
      (state, action) => {
        const formOptions = action.payload.map((item: DrivingLicense) => ({
          value: item.id,
          label: item.category,
        }));

        state.drivingLicenseCategories = formOptions;
      }
    );
  },
});

export const drivingLicenseReducer = DrivingLicenseSlice.reducer;
