import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { PsychologicalConsultationOpinion } from "../../../api/documents/consultationApi";
import { handlePending, handleReject } from "../../../utils/redux";
import {
  fetchPsychologicalConsultationOpinion,
  updatePsychologicalConsultationOpinion,
} from "../../actions/documentation/consultationActions";
import { fetchSelectedExamineeData } from "../../actions/examinees/selectedExamineeActions";

interface PsychologicalConsultationOpinionSlice extends BaseApiState {
  document?: PsychologicalConsultationOpinion;
}

const initialState: PsychologicalConsultationOpinionSlice = {
  document: undefined,
  succeeded: false,
  loading: false,
  error: false,
};
const PsychologicalConsulationOpinionSlice = createSlice({
  name: "psychologicalConsultationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPsychologicalConsultationOpinion.pending, handlePending)
      .addCase(fetchSelectedExamineeData.rejected, handleReject)
      .addCase(
        fetchPsychologicalConsultationOpinion.fulfilled,
        (state, action) => {
          state.document = action.payload.results;
          state.loading = false;
          state.succeeded = true;
        }
      )
      .addCase(updatePsychologicalConsultationOpinion.pending, handlePending)
      .addCase(updatePsychologicalConsultationOpinion.rejected, handleReject)
      .addCase(updatePsychologicalConsultationOpinion.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const PsychologicalConsultationOpinionReducer =
  PsychologicalConsulationOpinionSlice.reducer;
