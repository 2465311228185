import { createAsyncThunk } from "@reduxjs/toolkit";
import { ArchiveExamination } from "../../../api/archiveApi";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { archiveExaminationsURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const fetchArchiveExaminations = createAsyncThunk<
  ArchiveExamination[] | undefined,
  string,
  AsyncThunkConfig
>("archive/examinations/fetch", async (legacyUserId, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<ArchiveExamination[]>>(
      `${archiveExaminationsURL}?legacy_user_id=${legacyUserId}`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }
    dispatchNotification("error");
    throw err;
  }
});
