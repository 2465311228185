import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
    ApiError,
    ApiResponse,
    AsyncThunkConfig,
    isAxiosError,
} from "../../../api/axiosConfig";
import { PsychUserData } from "../../../api/psychologicalLabApi";
import { dispatchNotification } from "../../../utils/redux";
import { psychologicalUserURL } from "../../../utils/paths";

export const fetchSelectedPsychUserData = createAsyncThunk<
    PsychUserData,
    number,
    AsyncThunkConfig
>("selectedPsychUser/fetchSelectedPsychUserData", async (psychUserId, thunkAPI) => {
    try {
        const response = await client.get<ApiResponse<PsychUserData>>(
            `${psychologicalUserURL}${psychUserId}/`
        );
        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message);

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});


