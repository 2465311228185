import {
  fetchInterview,
  updateInterview,
} from "../../actions/documentation/interviewActions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { handlePending, handleReject } from "../../../utils/redux";
import { Interview } from "../../../api/interviewApi";
import {
  InterviewAllQuestions,
  SubmissionData,
} from "../../../components/DocumentationManagment/Interview/utils/types";

interface InterviewState extends BaseApiState {
  interview?: Partial<SubmissionData>;
  questions: Partial<Interview>;
}

const InterviewTabsOrder = {
  currentPsychophysicalState: 1,
  socialAndFamilySituation: 2,
  observation: 3,
};

const initialState: InterviewState = {
  interview: undefined,
  questions: {},
  succeeded: false,
  loading: false,
  error: false,
};

const InterviewSlice = createSlice({
  name: "interviewSlice",
  initialState,
  reducers: {
    resetInterview(state) {
      state.interview = undefined;
    },
    addQuestions(state, action: PayloadAction<InterviewAllQuestions>) {
      state.questions = { ...state.questions, ...action.payload };
    },
    resetQuestions(state) {
      state.questions = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterview.pending, handlePending)
      .addCase(fetchInterview.rejected, handleReject)
      .addCase(fetchInterview.fulfilled, (state, action) => {
        state.interview = Object.assign(
          { ...InterviewTabsOrder },
          action.payload.results.interview
        );
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(updateInterview.pending, handlePending)
      .addCase(updateInterview.rejected, handleReject)
      .addCase(updateInterview.fulfilled, (state, action) => {
        state.interview = undefined;
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const InterviewReducer = InterviewSlice.reducer;
export const { addQuestions, resetQuestions, resetInterview } =
  InterviewSlice.actions;
