import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../api/axiosConfig";
import { onboardingSendRequest } from "../actions/onboardingActions";
import { handlePending, handleReject } from "../../utils/redux";

interface OnboardingState extends BaseApiState {
  password?: string;
}

const initialState: OnboardingState = {
  password: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingPassword(state, action) {
      state.password = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onboardingSendRequest.fulfilled, (state) => {
      state.loading = false;
      state.succeeded = true;
    });
    builder.addCase(onboardingSendRequest.pending, (state) => {
      handlePending(state);
      state.password = undefined;
    });
    builder.addCase(onboardingSendRequest.rejected, handleReject);
  },
});

export const onboardingReducer = OnboardingSlice.reducer;

export const { setOnboardingPassword } = OnboardingSlice.actions;
