import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { handlePending, handleReject } from "../../../utils/redux";
import {
  fetchExaminationCard,
  submitExaminationCard,
  updateExaminationCardWithDefaultExaminationCourse,
} from "../../actions/documentation/examinationCardActions";
import { ExaminationCard } from "../../../api/documents/examinationCardApi";

interface JudgementSlice extends BaseApiState {
  document?: ExaminationCard;
}

const initialState: JudgementSlice = {
  document: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const ExaminationCardSlice = createSlice({
  name: "ExaminationCardSlice",
  initialState,
  reducers: {
    resetExaminationCardData(state) {
      state.document = undefined;
    },
    updateExaminationExtendedResultsList(state, action) {
      if (state.document && state.document.examinationExtendedResultsList) {
        state.document.examinationExtendedResultsList = action.payload.examinationExtendedResultsList;
      }
    },
    updateSelectedExaminationAimList(state, action) {
      if (state.document) {
        if (!state.document.examinationAim) {
          state.document.examinationAim = [];
        }
        state.document.examinationAim = action.payload.userValue;
      }
      if (action.payload.indexArray.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
        action.payload.indexArray.forEach((element: number) => {
          if (state.document && state.document.judgment.judgment) {
            switch (element) {
              case 0:
                state.document.judgment.judgment.drivingVehiclesContraindication = "false";
                break;
              case 1:
                state.document.judgment.judgment.professionalDriverContraindication = "false";
                break;
              case 2:
                state.document.judgment.judgment.emergencyVehicleDriverContraindication = "false";
                break;
              case 3:
                state.document.judgment.judgment.drivingInstructorContraindication = "false";
                break;
            }
          }
        });
      }
    },
    updateSelectedExaminationDrivingCategoriesList(state, action) {
      if (state.document && state.document.drivingLicense) {
        state.document.drivingLicense = action.payload;
      }
    },
    updateSelectedT2DJugmnetList(state, action) {
      if (state.document && state.document.judgment.judgment) {
        state.document.judgment.judgment.drivingVehiclesContraindication = action.payload[0].checked || "false";
        state.document.judgment.judgment.professionalDriverContraindication = action.payload[1].checked || "false";
        state.document.judgment.judgment.emergencyVehicleDriverContraindication = action.payload[2].checked || "false";
        state.document.judgment.judgment.drivingInstructorContraindication = action.payload[3].checked || "false";
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExaminationCard.pending, handlePending)
      .addCase(fetchExaminationCard.rejected, handleReject)
      .addCase(fetchExaminationCard.fulfilled, (state, action) => {
        if (action.payload.results.judgment.judgment === null && action.payload.results.methodology === "TEST2DRIVE") {
          action.payload.results.judgment.judgment = {
            drivingVehiclesContraindication: false,
            professionalDriverContraindication: false,
            emergencyVehicleDriverContraindication: false,
            drivingInstructorContraindication: false,
          };
        }
        state.document = action.payload.results;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(submitExaminationCard.pending, handlePending)
      .addCase(submitExaminationCard.rejected, handleReject)
      .addCase(submitExaminationCard.fulfilled, (state, action) => {
        state.document = action.payload.results;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(
        updateExaminationCardWithDefaultExaminationCourse.pending,
        handlePending
      )
      .addCase(
        updateExaminationCardWithDefaultExaminationCourse.rejected,
        handleReject
      )
      .addCase(
        updateExaminationCardWithDefaultExaminationCourse.fulfilled,
        (state, action) => {
          state.document = action.payload.results;
          state.loading = false;
          state.succeeded = true;
        }
      );
  },
});

export const ExaminationCardReducer = ExaminationCardSlice.reducer;
export const { resetExaminationCardData, updateExaminationExtendedResultsList,
  updateSelectedExaminationAimList, updateSelectedExaminationDrivingCategoriesList,
  updateSelectedT2DJugmnetList } = ExaminationCardSlice.actions;
