import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { handlePending, handleReject } from "../../../utils/redux";
import { NormSet } from "../../../api/documentationApi";
import { fetchNormSets } from "../../actions/documentation/normsActions";

interface NormSetsState extends BaseApiState {
  normSets?: NormSet[];
}

const initialState: NormSetsState = {
  normSets: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const NormSetSlice = createSlice({
  name: "documentation/normSets/fetchAll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNormSets.pending, handlePending)
      .addCase(fetchNormSets.rejected, handleReject)
      .addCase(fetchNormSets.fulfilled, (state, action) => {
        state.normSets = action.payload?.results;
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const NormSetReducer = NormSetSlice.reducer;
