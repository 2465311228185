import { examinationURL } from "../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { BackendDateFormat, FormatDate } from "../../../utils";
import { dispatchNotification } from "../../../utils/redux";
import { I18n } from "react-redux-i18n";
import { Judgment } from "../../../api/documents/judgementApi";
import { AllJudgments } from "../../../components/DocumentationManagment/Judgment/types";
import {
  hasAppealDeadlineField,
  hasNextExaminationField,
} from "../../../components/DocumentationManagment/Judgment/utils";
import { DocumentParams } from "../../../api/documentationApi";
import { downloadFileFromArraybuffer } from "../../../utils/download";
import { selectLanguage } from "../../selectors/i18nSelectors";
import { RootState } from "../../store";

export const fetchJudgment = createAsyncThunk<
  ApiResponse<Judgment>,
  DocumentParams,
  AsyncThunkConfig
>("documentation/judgment/get", async (data, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<Judgment>>(
      `${examinationURL}${data.examinationId}/documents/${data.documentId}/`
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const updateJudgment = createAsyncThunk<
  ApiResponse<Judgment>,
  AllJudgments & DocumentParams,
  AsyncThunkConfig
>("documentation/judgment/put", async (data, thunkAPI) => {
  const { examinationId, documentId, ...rest } = data;

  const formatedIssueDate = FormatDate(rest.issueDate, BackendDateFormat);

  if (hasNextExaminationField(rest)) {
    const formatedNextExaminationDate = FormatDate(
      rest.nextExamination,
      BackendDateFormat
    );

    rest.nextExamination = formatedNextExaminationDate;
  }

  if (hasAppealDeadlineField(rest)) {
    const formatedAppealDeadlineDate = rest.appealDeadline
      ? FormatDate(rest.appealDeadline, BackendDateFormat)
      : null;

    rest.appealDeadline = formatedAppealDeadlineDate;
  }

  try {
    const response = await instance.patch<ApiResponse<Judgment>>(
      `${examinationURL}${examinationId}/documents/${documentId}/`,
      { ...rest, issueDate: formatedIssueDate }
    );

    dispatchNotification(
      "success",
      I18n.t("DocumentationManagement.snackbar.save")
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const downloadJudgment = createAsyncThunk<
  undefined,
  DocumentParams,
  AsyncThunkConfig
>(
  "documentation/judgment/download",
  async ({ examinationId, documentId }, thunkAPI) => {
    try {
      const languageCode = selectLanguage(thunkAPI.getState() as RootState);

      const response = await instance.get<Blob>(
        `${examinationURL}${examinationId}/documents/${documentId}/download/`,
        {
          responseType: "arraybuffer",
          headers: { "Accept-Language": languageCode },
        }
      );

      downloadFileFromArraybuffer(response, "application/pdf");
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);
