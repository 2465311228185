import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import {
  LegalBasis,
  PsychologicalTestFetchingResponse,
} from "../../../api/examinationApi";
import { Examinee } from "../../../api/examineesApi";
import { handlePending, handleReject } from "../../../utils/redux";
import {
  fetchLegalBases,
  fetchPsychologicalTests,
  assignExamination,
  fetchLicensedMethodologies
} from "../../actions/examination/examinationAssigmentActions";
import { fetchSelectedExamineeData } from "../../actions/examinees/selectedExamineeActions";

export interface ExaminationAssignmentState extends BaseApiState {
  selectedExaminee?: Examinee;
  legalBases: LegalBasis[];
  psychologicalTests: PsychologicalTestFetchingResponse;
  chosenPsychologicalTests: number[];
  chosenLegalBases: number[];
  refreshNeeded: boolean;
  licensedMethodologies: number[];
}

const examinationStateInitialState: ExaminationAssignmentState = {
  selectedExaminee: undefined,
  legalBases: [],
  psychologicalTests: { tests: [] },
  chosenPsychologicalTests: [],
  chosenLegalBases: [],
  licensedMethodologies: [],
  refreshNeeded: false,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const ExaminationAssignmentSlice = createSlice({
  name: "examinationAssignmentSlice",
  initialState: examinationStateInitialState,
  reducers: {
    clearExaminationAssignmentState(state) {
      state.selectedExaminee = undefined;
      state.chosenLegalBases = [];
    },
    setChosenLegalBasis(state, action: PayloadAction<number[]>) {
      state.chosenLegalBases = action.payload;
    },
    setChosenPsychologicalTests(state, action: PayloadAction<number[]>) {
      state.chosenPsychologicalTests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLegalBases.pending, handlePending)
      .addCase(fetchLegalBases.rejected, handleReject)
      .addCase(fetchLegalBases.fulfilled, (state, action) => {
        state.legalBases = action.payload;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchLicensedMethodologies.pending, handlePending)
      .addCase(fetchLicensedMethodologies.rejected, handleReject)
      .addCase(fetchLicensedMethodologies.fulfilled, (state, action) => {
        state.licensedMethodologies = action.payload;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchPsychologicalTests.pending, handlePending)
      .addCase(fetchPsychologicalTests.rejected, handleReject)
      .addCase(fetchPsychologicalTests.fulfilled, (state, action) => {
        state.psychologicalTests = action.payload;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(assignExamination.pending, handlePending)
      .addCase(assignExamination.rejected, handleReject)
      .addCase(assignExamination.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchSelectedExamineeData.pending, handlePending)
      .addCase(fetchSelectedExamineeData.fulfilled, (state, action) => {
        state.selectedExaminee = action.payload;
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(fetchSelectedExamineeData.rejected, handleReject);
  },
});

export const examinationAssignmentReducer = ExaminationAssignmentSlice.reducer;
export const {
  clearExaminationAssignmentState,
  setChosenLegalBasis,
  setChosenPsychologicalTests,
} = ExaminationAssignmentSlice.actions;
