import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../api/axiosConfig";
import { ChangePasswordApi, ChangePasswordExpiredApi, PsychologicalLabInfo } from "../../api/userApi";
import {
  changePasswordURL,
  changePasswordExpiredURL,
  changePinURL,
  getPsychologicalLabInfoURL,
} from "../../utils/paths";
import { dispatchNotification } from "../../utils/redux";
import { I18n } from "react-redux-i18n";

export const getPsychologicalLabInfo = createAsyncThunk<
  ApiResponse<PsychologicalLabInfo[]>,
  undefined,
  AsyncThunkConfig
>("user/psych-lab-info", async (arg_, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychologicalLabInfo[]>>(
      getPsychologicalLabInfoURL
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const changeUserPassword = createAsyncThunk<
  undefined,
  ChangePasswordApi,
  AsyncThunkConfig
>("user/change-password", async (changePasswordObj, thunkAPI) => {
  try {
    await client.post(changePasswordURL, {
      newPassword: changePasswordObj.newPassword,
      currPassword: changePasswordObj.currPassword,
    });

    dispatchNotification("success", I18n.t("Snackbars.passwordChangeSuccess"));
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const changeUserPasswordExpired = createAsyncThunk<
  undefined,
  ChangePasswordExpiredApi,
  AsyncThunkConfig
>("user/change-password-expired", async (changePasswordObj, thunkAPI) => {
  try {
    await client.post(changePasswordExpiredURL, {
      userId: changePasswordObj.userId,
      newPassword: changePasswordObj.newPassword,
      currPassword: changePasswordObj.currPassword,
    });
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const changeUserPin = createAsyncThunk<
  undefined,
  string,
  AsyncThunkConfig
>("user/change-pin", async (pin, thunkAPI) => {
  try {
    await client.post(changePinURL, {
      pin,
    });

    dispatchNotification("success", I18n.t("Snackbars.pinChangeSuccess"));
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
