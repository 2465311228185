import { examinationURL } from "../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { dispatchNotification } from "../../../utils/redux";
import { I18n } from "react-redux-i18n";
import { InterviewAPI, InterviewUpdate } from "../../../api/interviewApi";

export const fetchInterview = createAsyncThunk<
  ApiResponse<InterviewAPI>,
  string,
  AsyncThunkConfig
>("documentation/interview/get", async (examinationId, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<InterviewAPI>>(
      `${examinationURL}${examinationId}/interview/`
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const updateInterview = createAsyncThunk<
  ApiResponse<InterviewAPI>,
  InterviewUpdate,
  AsyncThunkConfig
>("documentation/interview/put", async (data, thunkAPI) => {
  try {
    const response = await instance.patch<ApiResponse<InterviewAPI>>(
      `${examinationURL}${data.examinationId}/interview/`,
      {
        interview: data.data,
      }
    );

    dispatchNotification(
      "success",
      I18n.t("DocumentationManagement.snackbar.save")
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
