import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";
import { generateOptions } from "../../utils/common";

export const getQuestionsOccupationalMedicine = () => {
  const question5Options = [
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5a"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5b"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5c"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5d"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5e"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5f"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question5g"
    ),
  ];

  const question13options = [
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question13a"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question13b"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question13c"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question13d"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question13e"
    ),
  ];

  const question14options = [
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question14a"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question14b"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question14c"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question14d"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question14e"
    ),
  ];

  const question18options = [
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question18a"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question18b"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question18c"
    ),
  ];

  const question19options = [
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question19a"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question19b"
    ),
    I18n.t(
      "Interview.questions.methodologies.occupationalMedicine.questionAnswers.question19c"
    ),
  ];

  const basicQuestions: InterviewQuestion[] = [
    {
      type: "TEXT",
      header: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.headers.workInformation"
      ),
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question1"
      ),
    },
    {
      type: "TEXT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question2"
      ),
    },
    {
      type: "TEXT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question3"
      ),
    },
    {
      type: "TEXT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question4"
      ),
    },
    {
      type: "EXTENDED_AND",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question5"
      ),
      options: generateOptions(question5Options),
      extendedText: I18n.t(
        "Interview.questions.questionTexts.whatAreTheConsequences"
      ),
      extendedQuestionIndexes: [6],
    },
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question6"
      ),
    },
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question7"
      ),
    },

    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question8"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question8a"
      ),
      header: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.headers.workCourseInformation"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question9"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question9a"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question10"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question10a"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question11"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question11a"
      ),
    },
  ];

  const companyCarDriversQuestions: InterviewQuestion[] = [
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question12"
      ),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question13"
      ),
      options: generateOptions(question13options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question14"
      ),
      options: generateOptions(question14options),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question15"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question16"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question17"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question18"
      ),
      options: generateOptions(question18options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question19"
      ),
      options: generateOptions(question19options),
    },
  ];

  const workingAtHeightsQuestions: InterviewQuestion[] = [
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question20"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question21"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question22"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question23"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question23a"
      ),
    },
  ];

  const forkliftDriverQuestions: InterviewQuestion[] = [
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question24"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question25"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question26"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.occupationalMedicine.questionTexts.question26a"
      ),
    },
  ];

  return [
    basicQuestions,
    forkliftDriverQuestions,
    workingAtHeightsQuestions,
    companyCarDriversQuestions,
  ];
};
