import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
    ApiError,
    ApiResponse,
    AsyncThunkConfig,
    isAxiosError,
} from "../../../api/axiosConfig";
import { MethodologyTests, PsychLicData, PsychLicDeleteData, TestTypes } from "../../../api/psychologicalLabApi";
import { psychologicalLabsLicenceURL, psychologicalMethodologyTestCategories, psychologicalTestTypes } from "../../../utils/paths";
import { dispatchNotification, getTranslatedBackendMessage } from "../../../utils/redux";
import { CreatePsychLicData, DeletePsychLabLicDataRequest, EditPsychLabLicDataRequest } from "../../../components/Forms/types";
import { I18n } from "react-redux-i18n";

export const createPsychLic = createAsyncThunk<
    PsychLicData,
    CreatePsychLicData,
    AsyncThunkConfig
>("psychologicalLicCRUD/createPsychLic", async (data, thunkAPI,) => {
    try {
        const response = await client.post(`${psychologicalLabsLicenceURL}`, data);

        dispatchNotification("success", I18n.t("Snackbars.createPsychLicSuccess"));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            if (extractedError.details && extractedError.details.email && extractedError.details.email.length > 0) {
                dispatchNotification("error", I18n.t(`Forms.adminSnackbar.${extractedError.details.email[0] as string}`));
            } else {
                dispatchNotification("error", extractedError.message, true);
            }

            return thunkAPI.rejectWithValue(extractedError);
        }
    }
});

export const deletePsychLic = createAsyncThunk<
    undefined,
    DeletePsychLabLicDataRequest,
    AsyncThunkConfig
>("psychologicalLicCRUD/deletePsychLic", async (data, thunkAPI) => {
    try {
        await client.delete(`${psychologicalLabsLicenceURL}${data.id}/`, { data: { labId: data.labId } });
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification(
                "error",
                getTranslatedBackendMessage(extractedError.message)
            );

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});


export const editPsychLic = createAsyncThunk<
    PsychLicData,
    EditPsychLabLicDataRequest,
    AsyncThunkConfig
>("psychologicalLicCRUD/editPsychLic", async (data, thunkAPI,) => {
    try {
        const response = await client.patch<ApiResponse<PsychLicData>>(
            `${psychologicalLabsLicenceURL}${data.id}/`,
            { ...data }
        );

        dispatchNotification("success", I18n.t("Snackbars.editPsychLicSuccess"));

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            if (extractedError.details && extractedError.details.email && extractedError.details.email.length > 0) {
                let errDetail = "";
                switch (extractedError.details.email[0]) {
                    case "Enter a valid email address.":
                        errDetail = "email_wrong_error";
                        break;
                    case "The user with the provided email address already exists":
                        errDetail = "email_unique_error";
                        break;
                }
                dispatchNotification("error", I18n.t(`Forms.adminSnackbar.${errDetail}`));
            } else {
                dispatchNotification("error", extractedError.message, true);
            }

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});

export const fetchPsychLabLicData = createAsyncThunk<
    PsychLicData,
    number,
    AsyncThunkConfig
>("selectedPsychLab/fetchSelectedPsychLabLicData/get", async (licenseId, thunkAPI) => {
    try {
        const response = await client.get<ApiResponse<PsychLicData>>(
            `${psychologicalLabsLicenceURL}${licenseId}/`
        );

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message);

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});


export const fetchMethodologyTestCategories = createAsyncThunk<
    MethodologyTests[],
    undefined,
    AsyncThunkConfig
>("psychologicalLabs/fetchMethodologyTestCategories", async (_, thunkAPI) => {
    try {
        const response = await client.get<ApiResponse<MethodologyTests[]>>(psychologicalMethodologyTestCategories);

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message, true);

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});

export const fetchTestTypes = createAsyncThunk<
    TestTypes[],
    undefined,
    AsyncThunkConfig
>("psychologicalLabs/psychologicalTestTypes", async (_, thunkAPI) => {
    try {
        const response = await client.get<ApiResponse<TestTypes[]>>(psychologicalTestTypes);

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message, true);

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});
