import { createSlice } from "@reduxjs/toolkit";
import { RecentExamineesState } from "../../../api/examineesApi";
import { handlePending, handleReject } from "../../../utils/redux";
import { fetchRecentExaminees } from "../../actions/examinees/recentExamineesActions";

const initialState: RecentExamineesState = {
  examinees: [],
  succeeded: false,
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const recentExamineesSlice = createSlice({
  name: "recentExaminees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentExaminees.pending, handlePending)
      .addCase(fetchRecentExaminees.rejected, handleReject)
      .addCase(fetchRecentExaminees.fulfilled, (state, action) => {
        state.examinees = action.payload.sort(
          (a, b) =>
            new Date(b.dateCreated).getTime() -
            new Date(a.dateCreated).getTime()
        );

        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const recentExamineesReducer = recentExamineesSlice.reducer;
