import { createSlice } from "@reduxjs/toolkit";
import { ExaminationState } from "../../../api/examinationApi";
import { handlePending, handleReject } from "../../../utils/redux";
import {
  fetchExaminationDetails,
  fetchExaminationDocumentation,
} from "../../actions/examination/examinationActions";

const initialState: ExaminationState = {
  examination: undefined,
  documents: [],
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const ExaminationSlice = createSlice({
  name: "examinationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExaminationDocumentation.pending, handlePending)
      .addCase(fetchExaminationDocumentation.rejected, handleReject)
      .addCase(fetchExaminationDocumentation.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchExaminationDetails.pending, handlePending)
      .addCase(fetchExaminationDetails.rejected, handleReject)
      .addCase(fetchExaminationDetails.fulfilled, (state, action) => {
        state.examination = action.payload;
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const examinationReducer = ExaminationSlice.reducer;
