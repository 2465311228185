import { createSlice } from "@reduxjs/toolkit";
import { PsychologicalLabsListState } from "../../../api/psychologicalLabApi";
import { handlePending, handleReject } from "../../../utils/redux";
import { fetchPsychologicalLabs } from "../../actions/adminPanel/psychologicalLabsActions";

const initialState: PsychologicalLabsListState = {
  psychologicalLabs: [],
  succeeded: false,
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const psychologicalLabsSlice = createSlice({
  name: "psychologicalLabs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPsychologicalLabs.pending, handlePending)
      .addCase(fetchPsychologicalLabs.rejected, handleReject)
      .addCase(fetchPsychologicalLabs.fulfilled, (state, action) => {
        state.psychologicalLabs = action.payload;
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const psychologicalLabsReducer = psychologicalLabsSlice.reducer;
