import { createSlice } from "@reduxjs/toolkit";
import { PsychologicalLabsState } from "../../../api/psychologicalLabApi";
import { createPsychLab, deletePsychLab, editPsychLab } from "../../actions/adminPanel/psychologicalLabsActions";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../../utils/redux";

const initialState: PsychologicalLabsState = {
  psychologicalLabs: [],
  CUD: {
    succeeded: false,
    loading: true,
    error: false,
    errorMessage: "",
    errorDetails: {},
    refreshNeeded: false,
  },
};

const clearCUDErrors = (state: WritableDraft<PsychologicalLabsState>) => {
  state.CUD.error = false;
  state.CUD.errorMessage = undefined;
  state.CUD.errorDetails = undefined;
};

const handleCUDPending = (state: WritableDraft<PsychologicalLabsState>) => {
  state.CUD.succeeded = false;
  state.CUD.loading = true;
  clearCUDErrors(state);
};

const handleCUDRejected = <T>(
  state: WritableDraft<PsychologicalLabsState>,
  action: ErrorPayloadAction<T>
) => {
  state.CUD.succeeded = false;
  state.CUD.loading = false;

  if (action.payload) {
    state.CUD.error = action.payload.error;
    state.CUD.errorMessage = action.payload.message;
    state.CUD.errorDetails = action.payload.details;
  } else {
    state.CUD.error = true;
    state.CUD.errorMessage = undefined;
    state.CUD.errorDetails = undefined;
  }
};

const psychLabsSlice = createSlice({
  name: "psychologicalLabsCRUD",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPsychLab.pending, handleCUDPending)
      .addCase(createPsychLab.rejected, handleCUDRejected)
      .addCase(createPsychLab.fulfilled, (state, action) => {
        state.psychologicalLabs.unshift(action.payload);
        state.CUD.loading = false;
        state.CUD.succeeded = true;
      })
      .addCase(editPsychLab.pending, handleCUDPending)
      .addCase(editPsychLab.rejected, handleCUDRejected)
      .addCase(editPsychLab.fulfilled, (state) => {
        state.CUD.loading = false;
        state.CUD.succeeded = true;
      })
      .addCase(deletePsychLab.pending, handleCUDPending)
      .addCase(deletePsychLab.rejected, handleCUDRejected)
      .addCase(deletePsychLab.fulfilled, (state) => {
        state.CUD.loading = false;
        state.CUD.succeeded = true;
        state.CUD.refreshNeeded = true;
      });
  },
});

export const psychologicalLabsCRUDReducer = psychLabsSlice.reducer;
