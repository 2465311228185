import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  AsyncThunkConfig,
  isAxiosError,
  ApiError,
} from "../../api/axiosConfig";
import { dispatchNotification } from "../../utils/redux";

type onboardingArgs = {
  id: string | null;
  token: string;
  newPassword: string;
  pin: string;
};

export const onboardingSendRequest = createAsyncThunk<
  undefined,
  onboardingArgs,
  AsyncThunkConfig
>("onboardingSendRequest", async (data, thunkAPI) => {
  try {
    const { id, ...payload } = data;

    if (id) {
      await client.post(`/api/users/${id}/onboarding/`, payload);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
