import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { Judgment } from "../../../api/documents/judgementApi";
import { handlePending, handleReject } from "../../../utils/redux";
import {
  downloadJudgment,
  fetchJudgment,
  updateJudgment,
} from "../../actions/documentation/judgmentActions";

interface JudgmentSlice extends BaseApiState {
  document?: Judgment;
}

const initialState: JudgmentSlice = {
  document: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const JudgmentSlice = createSlice({
  name: "judgmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJudgment.pending, handlePending)
      .addCase(fetchJudgment.rejected, handleReject)
      .addCase(fetchJudgment.fulfilled, (state, action) => {
        state.document = action.payload.results;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(updateJudgment.pending, handlePending)
      .addCase(updateJudgment.rejected, handleReject)
      .addCase(updateJudgment.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(downloadJudgment.pending, handlePending)
      .addCase(downloadJudgment.rejected, handleReject)
      .addCase(downloadJudgment.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const JudgmentReducer = JudgmentSlice.reducer;
