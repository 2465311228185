import { examinationURL } from "../../../utils/paths";
import { AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { dispatchNotification } from "../../../utils/redux";
import { downloadFileFromArraybuffer } from "../../../utils/download";

interface InterpretativeReportParams {
  examinationId: string;
  normSet: number;
  recipient: string;
  language: string;
}

export const downloadInterpretativeReport = createAsyncThunk<
  undefined,
  InterpretativeReportParams,
  AsyncThunkConfig
>(
  "documentation/reports/interpretative",
  async ({ examinationId, normSet, recipient, language }, thunkAPI) => {
    try {
      const response = await instance.post<Blob>(
        `${examinationURL}${examinationId}/reports/interpretative/`,
        { normSet, recipient },
        {
          responseType: "arraybuffer",
          headers: { "Accept-Language": language },
        }
      );

      downloadFileFromArraybuffer(response, "application/pdf");
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);
