import instance, { ApiResponse } from "../api/axiosConfig";
import { isBrowser } from "./index";
import { navigate } from "gatsby-link";

export const userIdKeyName = "userId";

export async function isLoggedIn(): Promise<boolean> {
  if (!isBrowser) {
    return new Promise((resolve) => resolve(false));
  }
  const userId = localStorage.getItem(userIdKeyName);

  if (userId) {
    return new Promise((resolve) => resolve(true));
  }

  try {
    const response = await instance.get<ApiResponse<{ id: number }>>(
      loginVerifyURL
    );

    localStorage.setItem(userIdKeyName, response.data.results.id.toString());

    return true;
  } catch (err) {
    return false;
  }
}

export async function allowLoggedIn(): Promise<boolean> {
  const result = await isLoggedIn();

  if (!result) {
    await navigate(loginPath);
  }

  return result;
}

// gatsby pages' paths
export const loginPath = "/"; // page where login can be performed
export const loginAdminPath = "/adminsite"; // page where login can be performed
export const loginRedirectTo = "/app/dashboard/"; // page to redirect after a successful login
export const loginAdminRedirectTo = "/app/admin-dashboard/"; // page to redirect after a successful login
// API URLs
export const loginVerifyURL = "/api/users/login/verify/";
export const loginURL = "/api/users/login/";
export const logoutURL = "/api/users/logout/";
export const loginAdminURL = "/api/users/login/admin/";
export const updateAdminLabURL = "/api/users/change_lab/";
