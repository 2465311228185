import { Examination } from "../../../api/examinationApi";
import { createSlice } from "@reduxjs/toolkit";
import {
  deleteExamination,
  fetchContract,
  fetchExaminationList,
  fetchSelectedExamineeData,
} from "../../actions/examinees/selectedExamineeActions";
import { BaseApiState } from "../../../api/axiosConfig";
import { Examinee } from "../../../api/examineesApi";
import { handlePending, handleReject } from "../../../utils/redux";

export interface SelectedExamineeState extends BaseApiState {
  selectedExamineeId?: number;
  selectedExaminee?: Examinee;
  examinations: Examination[];
  refreshNeeded: boolean;
  loadingExamineeData: boolean;
}

const selectedExamineeInitialState: SelectedExamineeState = {
  selectedExamineeId: undefined,
  selectedExaminee: undefined,
  examinations: [],
  refreshNeeded: false,
  succeeded: false,
  loading: false,
  loadingExamineeData: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const SelectedExamineeSlice = createSlice({
  name: "selectedExamineeSlice",
  initialState: selectedExamineeInitialState,
  reducers: {
    setSelectedExamineId(state, action) {
      state.selectedExamineeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedExamineeData.pending, (state) => {
        state.loadingExamineeData = true;
      })
      .addCase(fetchSelectedExamineeData.fulfilled, (state, action) => {
        state.selectedExaminee = action.payload;
        state.loadingExamineeData = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(fetchSelectedExamineeData.rejected, (state) => {
        state.loadingExamineeData = false;

        state.error = true;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(fetchExaminationList.pending, handlePending)
      .addCase(fetchExaminationList.rejected, handleReject)
      .addCase(fetchExaminationList.fulfilled, (state, action) => {
        state.examinations = action.payload;
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(deleteExamination.pending, handlePending)
      .addCase(deleteExamination.rejected, handleReject)
      .addCase(deleteExamination.fulfilled, (state, action) => {
        state.examinations = state.examinations.filter(
          (exam) => exam.id !== action.payload?.id
        );
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchContract.rejected, handleReject)
      .addCase(fetchContract.pending, handlePending)
      .addCase(fetchContract.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const selectedExamineeReducer = SelectedExamineeSlice.reducer;
export const { setSelectedExamineId } = SelectedExamineeSlice.actions;
