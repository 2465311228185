// psychologicalUserCRUD
import { createSlice } from "@reduxjs/toolkit";
import { PsychologicalLabUserState } from "../../../api/psychologicalLabApi";
import { createPsychUser, editPsychUser, deletePsychUser } from "../../actions/adminPanel/psychologicalUserActions";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../../utils/redux";

const initialState: PsychologicalLabUserState = {
  psychologicalLabUser: [],
  CUD: {
    succeeded: false,
    loading: true,
    error: false,
    errorMessage: "",
    errorDetails: {},
    refreshNeeded: false,
  },
};

const clearCUDErrors = (state: WritableDraft<PsychologicalLabUserState>) => {
  state.CUD.error = false;
  state.CUD.errorMessage = undefined;
  state.CUD.errorDetails = undefined;
};

const handleCUDPending = (state: WritableDraft<PsychologicalLabUserState>) => {
  state.CUD.succeeded = false;
  state.CUD.loading = true;
  clearCUDErrors(state);
};

const handleCUDRejected = <T>(
  state: WritableDraft<PsychologicalLabUserState>,
  action: ErrorPayloadAction<T>
) => {
  state.CUD.succeeded = false;
  state.CUD.loading = false;
  if (action.payload) {
    state.CUD.error = action.payload.error;
    state.CUD.errorMessage = action.payload.message;
    state.CUD.errorDetails = action.payload.details;
  } else {
    state.CUD.error = true;
    state.CUD.errorMessage = undefined;
    state.CUD.errorDetails = undefined;
  }
};

const psychLabsUserSlice = createSlice({
  name: "psychologicalLabUserCRUD",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPsychUser.pending, handleCUDPending)
      .addCase(createPsychUser.rejected, handleCUDRejected)
      .addCase(createPsychUser.fulfilled, (state, action) => {
        state.psychologicalLabUser.unshift(action.payload);
        state.CUD.loading = false;
        state.CUD.succeeded = true;
      })
      .addCase(editPsychUser.pending, handleCUDPending)
      .addCase(editPsychUser.rejected, handleCUDRejected)
      .addCase(editPsychUser.fulfilled, (state) => {
        state.CUD.loading = false;
        state.CUD.succeeded = true;
        state.CUD.refreshNeeded = true;
      })
      .addCase(deletePsychUser.pending, handleCUDPending)
      .addCase(deletePsychUser.rejected, handleCUDRejected)
      .addCase(deletePsychUser.fulfilled, (state) => {
        state.CUD.loading = false;
        state.CUD.succeeded = true;
        state.CUD.refreshNeeded = true;
      });
  },
});

export const psychologicalLabUserCRUDReducer = psychLabsUserSlice.reducer;
