import { examinationURL } from "../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { dispatchNotification } from "../../../utils/redux";
import { ProtocolItem } from "../../../api/documentationApi";
import { downloadFileFromArraybuffer } from "../../../utils/download";

interface FetchAvailableParams {
  examinationId: string;
}

export const fetchAvailableProtocols = createAsyncThunk<
  ApiResponse<ProtocolItem[]>,
  FetchAvailableParams,
  AsyncThunkConfig
>("documentation/protocols/fetchAll", async (data, thunkAPI) => {
  try {
    const response = await instance.get<ApiResponse<ProtocolItem[]>>(
      `${examinationURL}${data.examinationId}/protocols/`
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

interface DownloadAllProtocolsParams {
  examinationId: string;
  languageCode: string;
}

export const downloadAllProtocols = createAsyncThunk<
  undefined,
  DownloadAllProtocolsParams,
  AsyncThunkConfig
>(
  "documentation/protocols/downloadAll",
  async ({ examinationId, languageCode }, thunkAPI) => {
    try {
      const response = await instance.get<Blob>(
        `${examinationURL}${examinationId}/protocols/download/`,
        {
          responseType: "arraybuffer",
          headers: { "Accept-Language": languageCode },
        }
      );

      downloadFileFromArraybuffer(response, "application/octet-stream");
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);

interface DownloadProtocolParams extends DownloadAllProtocolsParams {
  protocolId: number;
}

export const downloadProtocol = createAsyncThunk<
  undefined,
  DownloadProtocolParams,
  AsyncThunkConfig
>(
  "documentation/protocols/download",
  async ({ examinationId, protocolId, languageCode }, thunkAPI) => {
    try {
      const response = await instance.get<Blob>(
        `${examinationURL}${examinationId}/protocols/${protocolId}/download/`,
        {
          responseType: "arraybuffer",
          headers: { "Accept-Language": languageCode },
        }
      );

      downloadFileFromArraybuffer(response);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  }
);
