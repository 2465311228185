import {
  ExaminationCardOccupationalMedicineBasicInfoAnswer,
  ExaminationCardSubmit,
  ExaminationCardWritable,
} from "../api/documents/examinationCardApi";
import { BackendDateFormat, FormatDate } from "./index";
import { getQuestionsOccupationalMedicine } from "../components/DocumentationManagment/Interview/Sections/OccupationalMedicine/questions";
import {
  CurrentValue,
  handleQuestionAnswer,
} from "../components/DocumentationManagment/Interview/utils/anwers";
import { ParsedAnswer } from "../components/DocumentationManagment/Interview/utils/types";

export const formatSubmitData = (
  data: Partial<ExaminationCardWritable>
): Partial<ExaminationCardSubmit> => {
  const { occupationalMedicineBasicInfo, judgment, ...rest } = data;
  // replace "" values with null values
  const parsedData = Object.fromEntries(
    Object.entries(rest).map(([k, v]) => [k, v === "" ? null : v])
  );

  // format judgment.copySentDate
  parsedData.judgment =
    judgment && "copySentDate" in judgment
      ? {
        ...judgment,
        copySentDate: judgment?.copySentDate
          ? FormatDate(judgment?.copySentDate, BackendDateFormat)
          : null,
      }
      : judgment;

  return {
    ...(parsedData as Partial<ExaminationCardWritable>),
    // format previousExaminationDate
    previousExaminationDate: parsedData.previousExaminationDate
      ? FormatDate(
        parsedData.previousExaminationDate as string | null,
        BackendDateFormat
      )
      : null,
    occupationalMedicineBasicInfo: occupationalMedicineBasicInfo?.questions
      ?.length
      ? parseOccupationalMedicineBasicInfo(
        occupationalMedicineBasicInfo.questions
      )
      : null,
  };
};

const parseOccupationalMedicineBasicInfo = (
  answers: ExaminationCardOccupationalMedicineBasicInfoAnswer[]
): ParsedAnswer[] => {
  const [questions] = getQuestionsOccupationalMedicine(); // get "basic" questions only from the Interview

  return questions.map((question, index) => {
    const currentValue = answers[index] || "";

    return handleQuestionAnswer(
      question,
      currentValue as CurrentValue
    ) as ParsedAnswer;
  });
};
