import { ArchiveDocumentationState } from "../../../api/archiveApi";
import { createSlice } from "@reduxjs/toolkit";
import { handlePending, handleReject } from "../../../utils/redux";
import { fetchArchiveDocumentations } from "../../actions/examination/archiveDocumentationActions";

const initialState: ArchiveDocumentationState = {
  archiveDocumentationList: [],
  succeeded: false,
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const ArchiveDocumentationSlice = createSlice({
  name: "archiveDocumentation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchiveDocumentations.pending, handlePending)
      .addCase(fetchArchiveDocumentations.fulfilled, (state, action) => {
        state.archiveDocumentationList = action?.payload ?? [];
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchArchiveDocumentations.rejected, handleReject);
  },
});

export const archiveDocumentationReducer = ArchiveDocumentationSlice.reducer;
