import { createSlice } from "@reduxjs/toolkit";
import {
  getResetPasswordEmail,
  login,
  loginAdmin,
  logout,
  logoutAdmin,
  resetPasswordResetSuccess,
  resetPasswordSendRequest,
  verifyLogin,
  updateAdminLab
} from "../actions/authActions";
import { BaseApiState } from "../../api/axiosConfig";
import { handlePending, handleReject } from "../../utils/redux";
import { navigate } from "gatsby-link";
import { loginAdminPath, loginAdminRedirectTo, loginPath, loginRedirectTo, userIdKeyName } from "../../utils/auth";
import { WritableDraft } from "immer/dist/internal";
import { User } from "../../api/userApi";

export interface AuthState extends BaseApiState {
  user?: User;
  theme?: string;
  logAsUserError: boolean;
  logAsUserLoading: boolean;
  logAsUserSuccess: boolean;
}

const initialState: AuthState = {
  user: undefined,
  theme: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
  expiredUserId: undefined,
  logAsUserError: false,
  logAsUserLoading: false,
  logAsUserSuccess: false,
};

const handleLogout = (state: WritableDraft<AuthState>) => {
  state.user = undefined;
  state.loading = false;

  localStorage.removeItem(userIdKeyName);
  void navigate(loginPath);
};

const handleAdminLogout = (state: WritableDraft<AuthState>) => {
  state.user = undefined;
  state.loading = false;

  localStorage.removeItem(userIdKeyName);
  void navigate(loginAdminPath);
};

const handleLogoutFulfilled = (state: WritableDraft<AuthState>) => {
  state.succeeded = true;
  handleLogout(state);
};

const handleLogoutAdminFulfilled = (state: WritableDraft<AuthState>) => {
  state.succeeded = true;
  handleAdminLogout(state);
};

const handleLogoutRejected = (state: WritableDraft<AuthState>) => {
  state.succeeded = false;
  handleLogout(state);
};

const handleLogoutAdminRejected = (state: WritableDraft<AuthState>) => {
  state.succeeded = false;
  handleAdminLogout(state);
};

const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    clearAuthState(state) {
      state.user = undefined;
      state.theme = undefined;
      state.succeeded = false;
      state.loading = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorDetails = undefined;
      state.expiredUserId = undefined;
      state.logAsUserError = false;
      state.logAsUserLoading = false;
      state.logAsUserSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, handlePending)
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.theme = action.payload.theme;
        state.loading = false;
        state.succeeded = true;

        localStorage.setItem(userIdKeyName, String(state.user.id));

        if (!state.user.passwordExpired) {
          void navigate(loginRedirectTo);
        }
      })
      .addCase(login.rejected, handleReject)
      .addCase(loginAdmin.pending, handlePending)
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.theme = action.payload.theme;
        state.loading = false;
        state.succeeded = true;

        localStorage.setItem(userIdKeyName, String(state.user.id));

        if (!state.user.passwordExpired) {
          void navigate(loginAdminRedirectTo);
        }
      })
      .addCase(loginAdmin.rejected, handleReject)
      .addCase(verifyLogin.pending, handlePending)
      .addCase(verifyLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.theme = undefined;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(verifyLogin.rejected, (state) => {
        state.user = undefined;
        state.theme = undefined;
        state.loading = false;
        state.succeeded = false;
      })
      .addCase(updateAdminLab.pending, (state) => {
        state.logAsUserLoading = true;
        state.logAsUserSuccess = false;
        state.logAsUserError = false;
      })
      .addCase(updateAdminLab.rejected, (state) => {
        state.logAsUserLoading = false;
        state.logAsUserSuccess = false;
        state.logAsUserError = true;
      })
      .addCase(updateAdminLab.fulfilled, (state) => {
        state.logAsUserLoading = false;
        state.logAsUserSuccess = true;
        state.logAsUserError = false;
      })
      .addCase(logout.pending, handlePending)
      .addCase(logout.fulfilled, handleLogoutFulfilled)
      .addCase(logout.rejected, handleLogoutRejected)
      .addCase(logoutAdmin.pending, handlePending)
      .addCase(logoutAdmin.fulfilled, handleLogoutAdminFulfilled)
      .addCase(logoutAdmin.rejected, handleLogoutAdminRejected)
  },
});

export const authReducer = authSlice.reducer;
export const { clearAuthState } = authSlice.actions;

const initialResetPasswordState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const resetPassword = createSlice({
  name: "resetPassword",
  initialState: initialResetPasswordState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPasswordSendRequest.fulfilled, (state) => {
      state.loading = false;
      state.succeeded = true;
    });
    builder.addCase(resetPasswordSendRequest.pending, handlePending);
    builder.addCase(resetPasswordSendRequest.rejected, handleReject);
    builder.addCase(resetPasswordResetSuccess, (state) => {
      state.succeeded = false;
    });
  },
});

export const resetPasswordReducer = resetPassword.reducer;

const initialResetPasswordEmailState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const resetPasswordEmail = createSlice({
  name: "resetPasswordEmail",
  initialState: initialResetPasswordEmailState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getResetPasswordEmail.fulfilled, (state) => {
      state.loading = false;
      state.succeeded = true;
    });
    builder.addCase(getResetPasswordEmail.pending, handlePending);
    builder.addCase(getResetPasswordEmail.rejected, handleReject);
  },
});

export const resetPasswordEmailReducer = resetPasswordEmail.reducer;
