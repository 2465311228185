import { PsychLabActivity, PsychLabUser, PsychologicalLab } from "../../../api/psychologicalLabApi";
import { createSlice } from "@reduxjs/toolkit";
import { fetchActivityList, fetchSelectedPsychLabData, fetchUsersList } from "../../actions/adminPanel/selectedPsychLabActions";
import { BaseApiState } from "../../../api/axiosConfig";

export interface SelectedPsychLabState extends BaseApiState {
  selectedPsychLabId?: number;
  selectedPsychLab?: PsychologicalLab;
  refreshNeeded: boolean;
  loadingPsychLabData: boolean;
  loadingPsychUsers: boolean;
  loadingPsychActivities: boolean;
  users: PsychLabUser[];
  activities: PsychLabActivity[];
}

const selectedPsychLabInitialState: SelectedPsychLabState = {
  selectedPsychLabId: undefined,
  selectedPsychLab: undefined,
  refreshNeeded: false,
  succeeded: false,
  loading: false,
  loadingPsychLabData: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
  users: [],
  activities: [],
  loadingPsychUsers: false,
  loadingPsychActivities: false,
};

const SelectedPsychLabSlice = createSlice({
  name: "selectedPsychLab",
  initialState: selectedPsychLabInitialState,
  reducers: {
    setSelectedPsychLabId(state, action) {
      state.selectedPsychLabId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedPsychLabData.pending, (state) => {
        state.loadingPsychLabData = true;
      })
      .addCase(fetchSelectedPsychLabData.fulfilled, (state, action) => {
        state.selectedPsychLab = action.payload;
        state.loadingPsychLabData = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(fetchSelectedPsychLabData.rejected, (state) => {
        state.loadingPsychLabData = false;
        state.error = true;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(fetchUsersList.pending, (state) => {
        state.loadingPsychUsers = true;
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.loadingPsychUsers = false;
        state.error = true;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loadingPsychUsers = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(fetchActivityList.pending, (state) => {
        state.loadingPsychActivities = true;
      })
      .addCase(fetchActivityList.rejected, (state) => {
        state.loadingPsychActivities = false;
        state.error = true;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(fetchActivityList.fulfilled, (state, action) => {
        state.activities = action.payload;
        state.loadingPsychActivities = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
  },
});

export const selectedPsychLabReducer = SelectedPsychLabSlice.reducer;
export const { setSelectedPsychLabId } = SelectedPsychLabSlice.actions;
