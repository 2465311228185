import { SnackbarMessage, OptionsObject, Omit } from "notistack";
import store from "../store";
import { enqueueSnackbar } from "../reducers/notificationsReducers";

export interface Notification {
  message: SnackbarMessage;
  options?: Omit<OptionsObject, "defaultValue">;
}

export const dispatchEnqueueSnackbar = ({ message, options }: Notification) => {
  const { dispatch } = store;

  dispatch(
    enqueueSnackbar({
      notification: {
        message,
        options: {
          ...options,
          key: new Date().getTime() + Math.random(),
        },
      },
    })
  );
};
