import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { handlePending, handleReject } from "../../../utils/redux";
import { ProtocolItem } from "../../../api/documentationApi";
import {
  downloadAllProtocols,
  downloadProtocol,
  fetchAvailableProtocols,
} from "../../actions/documentation/protocolsActions";

interface AvailableProtocolsState extends BaseApiState {
  protocols?: ProtocolItem[];
}

const initialAvailableProtocolsState: AvailableProtocolsState = {
  protocols: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const AvailableProtocolsSlice = createSlice({
  name: "documentation/protocols/availableProtocolsSlice",
  initialState: initialAvailableProtocolsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableProtocols.pending, handlePending)
      .addCase(fetchAvailableProtocols.rejected, handleReject)
      .addCase(fetchAvailableProtocols.fulfilled, (state, action) => {
        state.protocols = action.payload?.results;
        state.loading = false;
        state.succeeded = true;
      });
  },
});

const initialDownloadProtocolState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const DownloadProtocolsSlice = createSlice({
  name: "documentation/protocols/downloadProtocolSlice",
  initialState: initialDownloadProtocolState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadProtocol.pending, handlePending)
      .addCase(downloadProtocol.rejected, handleReject)
      .addCase(downloadProtocol.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(downloadAllProtocols.pending, handlePending)
      .addCase(downloadAllProtocols.rejected, handleReject)
      .addCase(downloadAllProtocols.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const AvailableProtocolsReducer = AvailableProtocolsSlice.reducer;
export const DownloadProtocolsReducer = DownloadProtocolsSlice.reducer;
