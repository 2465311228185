import { FormatDate } from "../../../utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { I18n } from "react-redux-i18n";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { Examinee } from "../../../api/examineesApi";
import {
  CreateAnonymousExamineeData,
  CreateExamineeData,
  EditExamineeDataRequest,
} from "../../../components/Forms/types";
import { BackendDateFormat } from "../../../utils";
import { examineesURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const createExaminee = createAsyncThunk<
  Examinee,
  CreateExamineeData | CreateAnonymousExamineeData,
  AsyncThunkConfig
>("users/examinees/create", async (data, thunkAPI) => {
  const dataNotAnonymous = data as CreateExamineeData;

  (dataNotAnonymous.identificationNumber ?? "") !== ""
    ? (dataNotAnonymous.isIdentificationNumPesel = true)
    : (dataNotAnonymous.isIdentificationNumPesel = false);
  const formatedBirthDate = FormatDate(
    dataNotAnonymous.birthDate,
    BackendDateFormat
  );

  !dataNotAnonymous.isIdentificationNumPesel
    ? (dataNotAnonymous.identificationNumber = undefined)
    : dataNotAnonymous.identificationNumber;
  try {
    const response = await instance.post<ApiResponse<Examinee>>(examineesURL, {
      ...data,
      birthDate: formatedBirthDate,
      isIdentificationNumPesel: dataNotAnonymous.isIdentificationNumPesel,
      identificationNumber: dataNotAnonymous.identificationNumber,
    });

    dispatchNotification("success", I18n.t("Snackbars.createExamineeSuccess"));

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;
      const { message, details } = extractedError;

      if (details?.message === "Examinee_with_the_given_id_exists_in_this_psychological_lab") {
        dispatchNotification("error", "Examinee_with_the_given_id_exists_in_this_psychological_lab", true);
      } else {
        dispatchNotification("error", message, true);
      }

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const editExaminee = createAsyncThunk<
  Examinee,
  EditExamineeDataRequest,
  AsyncThunkConfig
>("users/examinees/update", async (data, thunkAPI) => {
  const formatedBirthDate = FormatDate(data.birthDate, BackendDateFormat);

  try {
    const response = await instance.patch<ApiResponse<Examinee>>(
      `${examineesURL}${data.id}/`,
      { ...data, birthDate: formatedBirthDate }
    );

    dispatchNotification("success", I18n.t("Snackbars.editExamineeSuccess"));

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;
      const { message, details } = extractedError;

      if (details?.message?.[0] === "Examinee_with_the_given_id_exists_in_this_psychological_lab") {
        dispatchNotification("error", "Examinee_with_the_given_id_exists_in_this_psychological_lab", true);
      } else {
        dispatchNotification("error", message, true);
      }

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
