import { pl } from "./locales/pl";
import { en } from "./locales/en";
import { es } from "./locales/es";
import { mx } from "./locales/mx";
import { uk } from "./locales/uk";
import { ru } from "./locales/ru";

/* eslint-disable camelcase */
const translations = {
  pl,
  en,
  es,
  mx,
  uk,
  ru,
};

export default translations;
