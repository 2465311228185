import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../../api/axiosConfig";
import { handlePending, handleReject } from "../../../utils/redux";
import { downloadInterpretativeReport } from "../../actions/documentation/interpretativeActions";

const initialInterpretativeReportState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};


const InterpretativeReportSlice = createSlice({
  name: "documentation/reports/interpretative",
  initialState: initialInterpretativeReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadInterpretativeReport.pending, handlePending)
      .addCase(downloadInterpretativeReport.rejected, handleReject)
      .addCase(downloadInterpretativeReport.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      });
  },
});

export const InterpretativeReportReducer = InterpretativeReportSlice.reducer;
