import { createSlice } from "@reduxjs/toolkit";
import { handlePending, handleReject } from "../../utils/redux";
import { BaseApiState } from "../../api/axiosConfig";
import { PsychologicalLabInfo } from "../../api/userApi";
import {
  changeUserPassword,
  changeUserPasswordExpired,
  changeUserPin,
  getPsychologicalLabInfo,
} from "../actions/userActions";

export interface UserPsychologicalLabState extends BaseApiState {
  psychologicalLabInfo?: PsychologicalLabInfo;
}

const userPsychologicalLabStateInitialState: UserPsychologicalLabState = {
  psychologicalLabInfo: undefined,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const userPsychologicalLabSlice = createSlice({
  name: "userPsychologicalLabSlice",
  initialState: userPsychologicalLabStateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPsychologicalLabInfo.pending, handlePending)
      .addCase(getPsychologicalLabInfo.fulfilled, (state, action) => {
        [state.psychologicalLabInfo] = action.payload.results;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(getPsychologicalLabInfo.rejected, handleReject);
  },
});

export const userPsychologicalLabReducer = userPsychologicalLabSlice.reducer;

const userChangePasswordInitialState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const userChangePasswordSlice = createSlice({
  name: "userChangePasswordSlice",
  initialState: userChangePasswordInitialState,
  reducers: {
    clearUserChangePasswordState(state) {
      state.succeeded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeUserPassword.pending, handlePending)
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(changeUserPassword.rejected, handleReject);
  },
});

export const userChangePasswordReducer = userChangePasswordSlice.reducer;

const changeUserPasswordExpiredSlice = createSlice({
  name: "userPasswordExpiredSlice",
  initialState: userChangePasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeUserPasswordExpired.pending, handlePending)
      .addCase(changeUserPasswordExpired.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(changeUserPasswordExpired.rejected, handleReject);
  },
});

export const userPasswordExpiredReducer = changeUserPasswordExpiredSlice.reducer;

export const { clearUserChangePasswordState } = userChangePasswordSlice.actions;

const userChangePinInitialState: BaseApiState = {
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const userChangePinSlice = createSlice({
  name: "userChangePinSlice",
  initialState: userChangePinInitialState,
  reducers: {
    clearUserChangePinState(state) {
      state.succeeded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeUserPin.pending, handlePending)
      .addCase(changeUserPin.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(changeUserPin.rejected, handleReject);
  },
});

export const userChangePinReducer = userChangePinSlice.reducer;
export const { clearUserChangePinState } = userChangePinSlice.actions;
