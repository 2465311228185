import { examinationURL } from "./../../../utils/paths";
import { ApiResponse, AsyncThunkConfig } from "./../../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance, { ApiError, isAxiosError } from "../../../api/axiosConfig";
import { PsychologicalConsultationOpinion } from "../../../api/documents/consultationApi";
import { PsychologicalConsultationOpinionData } from "../../../components/DocumentationManagment/types";
import { BackendDateFormat, FormatDate } from "../../../utils";
import { dispatchNotification } from "../../../utils/redux";
import { I18n } from "react-redux-i18n";
import { DocumentParams } from "../../../api/documentationApi";

export const fetchPsychologicalConsultationOpinion = createAsyncThunk<
  ApiResponse<PsychologicalConsultationOpinion>,
  DocumentParams,
  AsyncThunkConfig
>("documentation/consulation/get", async (data, thunkAPI) => {
  try {
    const response = await instance.get<
      ApiResponse<PsychologicalConsultationOpinion>
    >(`${examinationURL}${data.examinationId}/documents/${data.documentId}/`);

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const updatePsychologicalConsultationOpinion = createAsyncThunk<
  ApiResponse<PsychologicalConsultationOpinion>,
  PsychologicalConsultationOpinionData & DocumentParams,
  AsyncThunkConfig
>("documentation/consulation/put", async (data, thunkAPI) => {
  const formatedIssueDate = FormatDate(data.issueDate, BackendDateFormat);

  try {
    const response = await instance.patch<
      ApiResponse<PsychologicalConsultationOpinion>
    >(`${examinationURL}${data.examinationId}/documents/${data.documentId}/`, {
      ...data,
      issueDate: formatedIssueDate,
    });

    dispatchNotification(
      "success",
      I18n.t("DocumentationManagement.snackbar.save")
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
