import { createSlice } from "@reduxjs/toolkit";
import { handlePending, handleReject } from "../../../utils/redux";
import { MethodologyTests } from "../../../api/psychologicalLabApi";
import { InputOptions, MethodologyOptions, TestTypes } from "../../../components/Forms/types";
import { fetchMethodologyTestCategories, fetchTestTypes } from "../../actions/adminPanel/psychologicalLicActions";
import { BaseApiState } from "../../../api/axiosConfig";

export interface MethodologyTestState extends BaseApiState {
  methodologyTest: InputOptions[];
  methodologyOptions: MethodologyOptions[];
  testTypesOptions: InputOptions[];
  testTypes: TestTypes[];
  licensedMethodologies: number[];
}

const initialState: MethodologyTestState = {
  licensedMethodologies: [],
  methodologyTest: [],
  methodologyOptions: [],
  succeeded: false,
  error: false,
  loading: false,
  testTypes: [],
  testTypesOptions: [],
};

const MethodologyTestSlice = createSlice({
  name: "methodologyTests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMethodologyTestCategories.rejected, handleReject)
    builder.addCase(fetchMethodologyTestCategories.pending, handlePending)
    builder.addCase(
      fetchMethodologyTestCategories.fulfilled,
      (state, action) => {
        const formOptions = action.payload.map((item: MethodologyTests) => ({
          value: item.id,
          label: item.name,
        }));
        state.methodologyTest = formOptions;
        state.methodologyOptions = [...action.payload];
      }
    )
    builder.addCase(fetchTestTypes.rejected, handleReject)
    builder.addCase(fetchTestTypes.pending, handlePending)
    builder.addCase(
      fetchTestTypes.fulfilled,
      (state, action) => {
        state.testTypes = [...action.payload];
        const formOptions = action.payload.map((item: TestTypes) => ({
          value: item.id,
          label: item.name,
        }));
        state.testTypesOptions = formOptions;
        state.loading = false;
      }
    );
  },
});

export const methodologyTestReducer = MethodologyTestSlice.reducer;
