import { createSlice } from "@reduxjs/toolkit";
import { ExamineesState } from "../../../api/examineesApi";
import {
  createExaminee,
  editExaminee,
} from "../../actions/examinees/examineesActions";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../../utils/redux";

const initialState: ExamineesState = {
  examinees: [],
  CUD: {
    succeeded: false,
    loading: true,
    error: false,
    errorMessage: "",
    errorDetails: {},
  },
};

const clearCUDErrors = (state: WritableDraft<ExamineesState>) => {
  state.CUD.error = false;
  state.CUD.errorMessage = undefined;
  state.CUD.errorDetails = undefined;
};

const handleCUDPending = (state: WritableDraft<ExamineesState>) => {
  state.CUD.succeeded = false;
  state.CUD.loading = true;
  clearCUDErrors(state);
};

const handleCUDRejected = <T>(
  state: WritableDraft<ExamineesState>,
  action: ErrorPayloadAction<T>
) => {
  state.CUD.succeeded = false;
  state.CUD.loading = false;

  if (action.payload) {
    state.CUD.error = action.payload.error;
    state.CUD.errorMessage = action.payload.message;
    state.CUD.errorDetails = action.payload.details;
  } else {
    state.CUD.error = true;
    state.CUD.errorMessage = undefined;
    state.CUD.errorDetails = undefined;
  }
};

const examineesSlice = createSlice({
  name: "examinees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createExaminee.pending, handleCUDPending)
      .addCase(createExaminee.rejected, handleCUDRejected)
      .addCase(createExaminee.fulfilled, (state, action) => {
        state.examinees.unshift(action.payload);
        state.CUD.loading = false;
        state.CUD.succeeded = true;
      })
      .addCase(editExaminee.pending, handleCUDPending)
      .addCase(editExaminee.rejected, handleCUDRejected)
      .addCase(editExaminee.fulfilled, (state) => {
        state.CUD.loading = false;
        state.CUD.succeeded = true;
      });
  },
});

export const examineesReducer = examineesSlice.reducer;
