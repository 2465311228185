import { i18nReducer } from "react-redux-i18n";
import { combineReducers } from "redux";
import {
  authReducer,
  resetPasswordReducer,
  resetPasswordEmailReducer,
} from "./authReducers";
import {
  directoryContentReducer,
  directoriesReducer,
  selectedDirectoryReducer,
} from "./directoriesReducers";
import { archiveDocumentationReducer } from "./examination/archiveDocumentationReducer";
import { archiveExaminationsReducer } from "./examination/archiveExaminationsReducer";
import { examinationAssignmentReducer } from "./examination/examinationAssigmentReducer";
import { examinationReducer } from "./examination/examinationReducer";
import { drivingLicenseReducer } from "./examinees/drivingLicenseReducers";
import notificationsReducers from "./notificationsReducers";
import { onboardingReducer } from "./onboardingReducers";
import { searchedExamineeResultsReducer } from "./searchedExamineeResultsReducers";
import {
  userPsychologicalLabReducer,
  userChangePasswordReducer,
  userChangePinReducer,
  userPasswordExpiredReducer,
} from "./userReducers";
import { PsychologicalConsultationOpinionReducer } from "./documentation/consultationReducers";
import {
  AvailableProtocolsReducer,
  DownloadProtocolsReducer,
} from "./documentation/protocolsReducers";
import { NormSetReducer } from "./documentation/normsReducers";
import { DiagnosticReportReducer } from "./documentation/reportReducers";
import { InterpretativeReportReducer } from "./documentation/interpretativeReducers";
import { JudgmentReducer } from "./documentation/judgmentReducers";
import { examineesReducer } from "./examinees/examineesCRUDReducers";
import { recentExamineesReducer } from "./examinees/recentExamineesReducers";
import { psychologicalLabsReducer } from "./adminPanel/psychologicalLabsReducers";
import { selectedExamineeReducer } from "./examinees/selectedExamineeReducers";
import { InterviewReducer } from "./documentation/interviewReducers";
import { ExaminationCardReducer } from "./documentation/examinationCardReducers";
import { psychologicalLabsCRUDReducer } from "./adminPanel/psychologicalLabsCRUDReducers";
import { selectedPsychLabReducer } from "./adminPanel/selectedPsychLabReducer";
import { psychologicalLabUserCRUDReducer } from "./adminPanel/psychologicalUserCRUDReducers";
import { selectedPsychUserReducer } from "./adminPanel/selectedPsychUserReducer";
import { psychologicalLabsLicReducer } from "./adminPanel/psychologicalLabsLicReducer";
import { methodologyTestReducer } from "./adminPanel/methodologyTestReducers";

export default combineReducers({
  auth: authReducer,
  i18n: i18nReducer,
  onboarding: onboardingReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordEmail: resetPasswordEmailReducer,
  searchedExamineeResults: searchedExamineeResultsReducer,
  directoryContent: directoryContentReducer,
  drivingLicenseCategories: drivingLicenseReducer,
  notifications: notificationsReducers,
  userPsychologicalLab: userPsychologicalLabReducer,
  userChangePassword: userChangePasswordReducer,
  userChangePasswordExpired: userPasswordExpiredReducer,
  userChangePin: userChangePinReducer,
  examinees: examineesReducer,
  recentExaminees: recentExamineesReducer,
  psychologicalLabs: psychologicalLabsReducer,
  psychologicalLabsLicCRUD: psychologicalLabsLicReducer,
  psychologicalLabsCRUD: psychologicalLabsCRUDReducer,
  psychologicalUserCRUD: psychologicalLabUserCRUDReducer,
  selectedPsychLab: selectedPsychLabReducer,
  methodologyTests: methodologyTestReducer,
  selectedPsychUser: selectedPsychUserReducer,
  psychLicData: psychologicalLabsLicReducer,
  directories: directoriesReducer,
  selectedDirectory: selectedDirectoryReducer,
  selectedExaminee: selectedExamineeReducer,
  archiveExamination: archiveExaminationsReducer,
  archiveDocumentations: archiveDocumentationReducer,
  examinationAssignment: examinationAssignmentReducer,
  examination: examinationReducer,
  documentation: combineReducers({
    interview: InterviewReducer,
    judgment: JudgmentReducer,
    psychologicalConsultationOpinion: PsychologicalConsultationOpinionReducer,
    protocols: combineReducers({
      availableProtocols: AvailableProtocolsReducer,
      downloadProtocols: DownloadProtocolsReducer,
    }),
    norms: NormSetReducer,
    reports: combineReducers({
      diagnostic: DiagnosticReportReducer,
      interpretative: InterpretativeReportReducer,
    }),
    examinationCard: ExaminationCardReducer,
  }),
});

