import { ArchiveExaminationState } from "../../../api/archiveApi";
import { createSlice } from "@reduxjs/toolkit";
import { fetchArchiveExaminations } from "../../actions/examination/archiveExaminationsActions";
import { handlePending, handleReject } from "../../../utils/redux";

const initialState: ArchiveExaminationState = {
  archiveExamination: [],
  succeeded: false,
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const ArchiveExaminationsSlice = createSlice({
  name: "archiveExaminations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchiveExaminations.pending, handlePending)
      .addCase(fetchArchiveExaminations.fulfilled, (state, action) => {
        state.archiveExamination = action?.payload ?? [];
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchArchiveExaminations.rejected, handleReject);
  },
});

export const archiveExaminationsReducer = ArchiveExaminationsSlice.reducer;
