import { createAsyncThunk } from "@reduxjs/toolkit";
import { ArchiveDocumentationList } from "../../../api/archiveApi";
import instance, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { archiveDocumentationsURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";

export const fetchArchiveDocumentations = createAsyncThunk<
  ArchiveDocumentationList[] | undefined,
  string,
  AsyncThunkConfig
>("archive/documentation/fetch", async (legacyAssessmentId, thunkAPI) => {
  try {
    const response = await instance.get<
      ApiResponse<ArchiveDocumentationList[]>
    >(`${archiveDocumentationsURL}?legacy_assessment_id=${legacyAssessmentId}`);

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }
    dispatchNotification("error");
    throw err;
  }
});
