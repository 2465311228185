import { createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../../api/axiosConfig";
import { Directory } from "../../api/directoriesApi";
import {
  addDirectory,
  changeExamineeDirectory,
  deleteDirectory,
  deleteExaminee,
  editDirectoryName,
  getAllDirectories,
  getDirectories,
  getDirectoryContent,
  getSelectedDirectoryDetails,
} from "../actions/directoriesActions";
import { handlePending, handleReject } from "../../utils/redux";
import { Examinee } from "../../api/examineesApi";

export interface SelectedDirectoryState extends BaseApiState {
  selectedDirectory?: Directory;
  refreshNeeded: boolean;
}

const selectedDirectoryStateInitialState: SelectedDirectoryState = {
  selectedDirectory: undefined,
  refreshNeeded: false,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const SelectedDirectorySlice = createSlice({
  name: "selectedDirectorySlice",
  initialState: selectedDirectoryStateInitialState,
  reducers: {
    setSelectedDirectory(state, action) {
      state.selectedDirectory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedDirectoryDetails.pending, handlePending)
      .addCase(getSelectedDirectoryDetails.fulfilled, (state, action) => {
        state.selectedDirectory = action.payload.results;
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(getSelectedDirectoryDetails.rejected, handleReject);
  },
});

export const selectedDirectoryReducer = SelectedDirectorySlice.reducer;
export const { setSelectedDirectory } = SelectedDirectorySlice.actions;

export interface DirectoryContentState extends BaseApiState {
  selectedExaminees: number[];
  selectedDirectory?: Directory;
  selectedDirectoryContent: Examinee[];
  refreshNeeded: boolean;
  pageCount: number;
  pageLimit: number;
  totalCount: number;
  currentPage: number;
}

const directoryContentInitialState: DirectoryContentState = {
  selectedExaminees: [],
  selectedDirectory: undefined,
  selectedDirectoryContent: [],
  refreshNeeded: false,
  pageCount: 1,
  pageLimit: 10,
  totalCount: 0,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
  currentPage: 1,
};

const DirectoryContentSlice = createSlice({
  name: "directoryContentSlice",
  initialState: directoryContentInitialState,
  reducers: {
    setSelectedExaminees(state, action) {
      state.selectedExaminees = action.payload;
    },
    setSelectedDirectoryContent(state, action) {
      state.selectedDirectoryContent = action.payload;
      state.currentPage = 1;
    },
    setCurrentPageDirectoryContent(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDirectoryContent.pending, handlePending)
      .addCase(getDirectoryContent.fulfilled, (state, action) => {
        state.selectedDirectoryContent = action.payload.results;
        state.pageCount = Math.ceil(action.payload.count / state.pageLimit);
        state.totalCount = action.payload.count;
        state.selectedExaminees = [];
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(getDirectoryContent.rejected, handleReject)
      .addCase(deleteExaminee.pending, handlePending)
      .addCase(deleteExaminee.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = true;
      })
      .addCase(deleteExaminee.rejected, handleReject)
      .addCase(changeExamineeDirectory.pending, handlePending)
      .addCase(changeExamineeDirectory.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = true;
      })
      .addCase(changeExamineeDirectory.rejected, handleReject);
  },
});

export const directoryContentReducer = DirectoryContentSlice.reducer;
export const {
  setSelectedExaminees,
  setSelectedDirectoryContent,
  setCurrentPageDirectoryContent,
} = DirectoryContentSlice.actions;

export interface DirectoriesState extends BaseApiState {
  directories: Directory[];
  allDirectories: Directory[];
  refreshNeeded: boolean;
  pageCount: number;
  pageLimit: number;
  totalCount: number;
}

const directoriesInitialState: DirectoriesState = {
  directories: [],
  allDirectories: [],
  refreshNeeded: false,
  pageCount: 1,
  pageLimit: 10,
  totalCount: 0,
  succeeded: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const DirectoriesSlice = createSlice({
  name: "directoriesSlice",
  initialState: directoriesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDirectories.pending, handlePending)
      .addCase(getDirectories.fulfilled, (state, action) => {
        state.directories = action.payload.results;
        state.pageCount = Math.ceil(action.payload.count / state.pageLimit);
        state.totalCount = action.payload.count;
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(getDirectories.rejected, handleReject)
      .addCase(getAllDirectories.pending, handlePending)
      .addCase(getAllDirectories.fulfilled, (state, action) => {
        state.allDirectories = action.payload.results;
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = false;
      })
      .addCase(getAllDirectories.rejected, handleReject)
      .addCase(deleteDirectory.pending, handlePending)
      .addCase(deleteDirectory.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = true;
      })
      .addCase(deleteDirectory.rejected, handleReject)
      .addCase(addDirectory.pending, handlePending)
      .addCase(addDirectory.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = true;
      })
      .addCase(addDirectory.rejected, handleReject)
      .addCase(editDirectoryName.pending, handlePending)
      .addCase(editDirectoryName.fulfilled, (state) => {
        state.loading = false;
        state.succeeded = true;
        state.refreshNeeded = true;
      })
      .addCase(editDirectoryName.rejected, handleReject);
  },
});

export const directoriesReducer = DirectoriesSlice.reducer;
