/* eslint-disable camelcase */
import {
  DualIntAnswer,
  ExtendedAndAnswer,
  ExtendedBoolAnswer,
  FormAnswers,
  InterviewQuestion,
  InterviewQuestionType,
  InterviewSection,
  OccupationalMedicineParsedAnswers,
  OccupationalMedicineQuestions,
  OccupationalMedicineSectionType,
  OccupationalMedicineValues,
  ParsedAnswer,
} from "./types";
import { I18n } from "react-redux-i18n";
import {
  isDualIntType,
  isStringNumberArray,
  isExtendedBoolType,
  isExtendedAndType,
} from "./common";

const handleDualIntAnswer = (
  values: DualIntAnswer,
  question: InterviewQuestion
) => {
  return {
    ...question,
    description_1: I18n.t("Forms.fields.years"),
    answer_1: values?.years || null,
    description_2: I18n.t("Forms.fields.months"),
    answer_2: values?.months || null,
  };
};

const handleAdditionalInfoAnswer = (values: FormAnswers) => {
  return {
    type: "TEXT" as InterviewQuestionType,
    question: I18n.t("Forms.fields.additionalInfo"),
    answer: values?.additionalInfo || undefined,
  };
};

const handleBoolAnswerText = (answer: boolean | null) => {
  if (answer === null) {
    return null;
  }

  return answer
    ? I18n.t("Forms.options.bool.true")
    : I18n.t("Forms.options.bool.false");
};

const handleBoolAnswer = (value: string, question: InterviewQuestion) => {
  const answer: boolean | null = value?.length ? JSON.parse(value) : null;

  return {
    ...question,
    answer,
    answerValue: handleBoolAnswerText(answer),
  };
};

const handleExtendedBoolAnswer = (
  value: ExtendedBoolAnswer,
  question: InterviewQuestion
) => {
  const answer: boolean | null =
    typeof value.answer === "boolean"
      ? value.answer
      : value.answer.length
        ? JSON.parse(value.answer)
        : null;

  return {
    ...question,
    sectionSubheader: (question.subheader) || null,
    answer,
    answerValue: handleBoolAnswerText(answer),
    additionalQuestion: question.extendedText,
    additionalInfo: (answer && value.extended) || null, // attach additionalInfo, only if answer is truthful
  };
};

const handleXorAnswer = (value: string, question: InterviewQuestion) => {
  const { options, ...rest } = question;

  const answer: string | null = value.length ? value : null;

  return {
    ...rest,
    answer,
    answerValue: answer && options ? options[+answer]?.label : null,
  };
};

const handleIntAnswer = (value: string, question: InterviewQuestion) => {
  return {
    ...question,
    answer: value || null,
  };
};

const handleAndAnswer = (value: string[], question: InterviewQuestion) => {
  const { options, ...rest } = question;

  return {
    ...rest,
    checkedIds: value.length ? value : null,
    answers: value.length
      ? options
        ?.filter((option) => value.includes(option.value as string))
        .map((option) => option.label)
      : null,
  };
};

const handleExtendedAndAnswer = (value: ExtendedAndAnswer, question: InterviewQuestion) => {
  const { options, ...rest } = question;

  const checkedIdsValue = value.answer.length ? value.answer.filter((question_checked: any) => {
    return typeof question_checked === "number";
  }) : null;

  return {
    ...rest,
    checkedIds: checkedIdsValue,
    answers: value.answer.length
      ? options
        ?.filter((option) => value.answer.includes(option.value as number))
        .map((option) => option.label)
      : null,
    additionalQuestion: question.extendedText,
    additionalInfo: (value.extended) || null,
  };
};

const handleTextAnswer = (
  value: string | undefined,
  question: InterviewQuestion
) => {
  return {
    ...question,
    answer: value || null,
  };
};

export type CurrentValue =
  | string
  | string[]
  | DualIntAnswer
  | ExtendedBoolAnswer
  | ExtendedAndAnswer;

export const handleQuestionAnswer = (
  question: InterviewQuestion,
  currentValue: CurrentValue
) => {
  switch (question.type) {
    case "XOR":
      return typeof currentValue === "string"
        ? handleXorAnswer(currentValue, question)
        : undefined;
    case "BOOL":
      return typeof currentValue === "string"
        ? handleBoolAnswer(currentValue, question)
        : undefined;
    case "DUAL_INT":
      return isDualIntType(currentValue)
        ? handleDualIntAnswer(currentValue as DualIntAnswer, question)
        : undefined;
    case "INT":
      return typeof currentValue === "string" ||
        typeof currentValue === "undefined"
        ? handleIntAnswer(currentValue, question)
        : undefined;
    case "TEXT":
      return typeof currentValue === "string" ||
        typeof currentValue === "undefined"
        ? handleTextAnswer(currentValue, question)
        : undefined;
    case "AND":
      return isStringNumberArray(currentValue)
        ? handleAndAnswer(currentValue as string[], question)
        : undefined;
    case "EXTENDED_BOOL":
      return isExtendedBoolType(currentValue)
        ? handleExtendedBoolAnswer(currentValue as ExtendedBoolAnswer, question)
        : undefined;
    case "EXTENDED_AND":
      return isExtendedAndType(currentValue)
        ? handleExtendedAndAnswer(currentValue as ExtendedAndAnswer, question)
        : undefined;
  }
};

export const handleSectionsMapping = (
  section: InterviewSection,
  values: FormAnswers,
  questions: InterviewQuestion[]
): ParsedAnswer[] => {
  const mappedAnswers = questions.map((question, index) => {
    const currentValue = values.question[index] || "";

    return handleQuestionAnswer(question, currentValue);
  });

  const AdditionalInfoSections = [
    "criticalEvents",
    "drivingVehicles",
    "socialAndFamilySituation",
    "observation",
    "securityAndDetectives",
  ];

  if (AdditionalInfoSections.indexOf(section) >= 0) {
    return [...mappedAnswers, handleAdditionalInfoAnswer(values)].filter(
      (answer) => answer !== undefined
    ) as ParsedAnswer[];
  }

  return mappedAnswers.filter(
    (answer) => answer !== undefined
  ) as ParsedAnswer[];
};

export const handleOccupationalMedicineMapping = (
  values: OccupationalMedicineValues,
  allQuestions: OccupationalMedicineQuestions
): OccupationalMedicineParsedAnswers => {
  return Object.fromEntries(
    Object.entries(allQuestions).map(([category, questions]) => {
      const mappedAnswers = questions.map((question, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const currentValue =
          values[category as OccupationalMedicineSectionType]?.question[
          index
          ] || "";

        return handleQuestionAnswer(question, currentValue);
      });

      return [
        category as OccupationalMedicineSectionType,
        mappedAnswers.filter(
          (answer) => answer !== undefined
        ) as ParsedAnswer[],
      ];
    })
  ) as OccupationalMedicineParsedAnswers;
};
