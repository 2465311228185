import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../api/axiosConfig";
import { examineesURL } from "../../utils/paths";
import { Examinee } from "../../api/examineesApi";
import { dispatchNotification } from "../../utils/redux";

export const fetchExaminees = createAsyncThunk<
  ApiResponse<Examinee[]>,
  string | undefined,
  AsyncThunkConfig
>("user/examinees", async (search, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<Examinee[]>>(
      !search ? examineesURL : `${examineesURL}?search=${search}`
    );

    return response.data;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message, true);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
