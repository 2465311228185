import { createSlice } from "@reduxjs/toolkit";
import { PsychologicalLabLicState } from "../../../api/psychologicalLabApi";
import { createPsychLic, editPsychLic, deletePsychLic, fetchPsychLabLicData } from "../../actions/adminPanel/psychologicalLicActions";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../../utils/redux";

const initialState: PsychologicalLabLicState = {
    psychologicalLabLic: { dateExpire: undefined, id: undefined, name: undefined, testTypes: undefined },
    loadingLicData: false,
    succeeded: false,
    refreshNeeded: false,
    error: false,
    errorMessage: undefined,
    errorDetails: undefined,

    CUD: {
        succeeded: false,
        loading: true,
        error: false,
        errorMessage: "",
        errorDetails: {},
        refreshNeeded: false,
    },
};

const clearCUDErrors = (state: WritableDraft<PsychologicalLabLicState>) => {
    state.CUD.error = false;
    state.CUD.errorMessage = undefined;
    state.CUD.errorDetails = undefined;
};

const handleCUDPending = (state: WritableDraft<PsychologicalLabLicState>) => {
    state.CUD.succeeded = false;
    state.CUD.loading = true;
    clearCUDErrors(state);
};

const handleCUDRejected = <T>(
    state: WritableDraft<PsychologicalLabLicState>,
    action: ErrorPayloadAction<T>
) => {
    state.CUD.succeeded = false;
    state.CUD.loading = false;
    if (action.payload) {
        state.CUD.error = action.payload.error;
        state.CUD.errorMessage = action.payload.message;
        state.CUD.errorDetails = action.payload.details;
    } else {
        state.CUD.error = true;
        state.CUD.errorMessage = undefined;
        state.CUD.errorDetails = undefined;
    }
};

const psychLabsLicSlice = createSlice({
    name: "psychologicalLabUserCRUD",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPsychLabLicData.pending, (state) => {
                state.loadingLicData = true;
            })
            .addCase(fetchPsychLabLicData.fulfilled, (state, action) => {
                state.psychologicalLabLic = action.payload;
                state.loadingLicData = false;
                state.succeeded = true;
                state.refreshNeeded = false;
            })
            .addCase(fetchPsychLabLicData.rejected, (state) => {
                state.loadingLicData = false;
                state.error = true;
                state.errorMessage = undefined;
                state.errorDetails = undefined;
            })
            .addCase(createPsychLic.pending, handleCUDPending)
            .addCase(createPsychLic.rejected, handleCUDRejected)
            .addCase(createPsychLic.fulfilled, (state, action) => {
                state.psychologicalLabLic = action.payload;
                state.CUD.loading = false;
                state.CUD.succeeded = true;
            })
            .addCase(editPsychLic.pending, handleCUDPending)
            .addCase(editPsychLic.rejected, handleCUDRejected)
            .addCase(editPsychLic.fulfilled, (state) => {
                state.CUD.loading = false;
                state.CUD.succeeded = true;
                state.CUD.refreshNeeded = true;
            })
            .addCase(deletePsychLic.pending, handleCUDPending)
            .addCase(deletePsychLic.rejected, handleCUDRejected)
            .addCase(deletePsychLic.fulfilled, (state) => {
                state.psychologicalLabLic = { id: 0 }
                state.loadingLicData = false;
                state.CUD.loading = false;
                state.CUD.succeeded = true;
                state.CUD.refreshNeeded = true;
            });
    },
});

export const psychologicalLabsLicReducer = psychLabsLicSlice.reducer;
