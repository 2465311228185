import { examinationURL, getContractURL } from "../../../utils/paths";
import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { DeleteExamination, Examination } from "../../../api/examinationApi";
import { Examinee } from "../../../api/examineesApi";
import { examineesURL } from "../../../utils/paths";
import { dispatchNotification } from "../../../utils/redux";
import { I18n } from "react-redux-i18n";
import { downloadFileFromArraybuffer } from "../../../utils/download";
import { selectLanguage } from "../../selectors/i18nSelectors";
import { RootState } from "../../store";
import { getTranslatedBackendMessage } from "../../../utils/redux";

export const fetchSelectedExamineeData = createAsyncThunk<
  Examinee,
  number,
  AsyncThunkConfig
>("user/examinee/get", async (examineeId, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<Examinee>>(
      `${examineesURL}${examineeId}/`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchExaminationList = createAsyncThunk<
  Examination[],
  number,
  AsyncThunkConfig
>("user/examinee/examination/get", async (examinee, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<Examination[]>>(
      examinationURL,
      { params: { examinee } }
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchContract = createAsyncThunk<
  undefined,
  string,
  AsyncThunkConfig
>("users/contract/get", async (examineeId: string, thunkAPI) => {
  try {
    const languageCode = selectLanguage(thunkAPI.getState() as RootState);

    const response = await client.get<Blob>(
      `${getContractURL}?examinee_id=${examineeId}`,
      {
        responseType: "arraybuffer",
        headers: { "Accept-Language": languageCode },
      }
    );

    downloadFileFromArraybuffer(response, "application/pdf");
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
export const deleteExamination = createAsyncThunk<
  DeleteExamination,
  string,
  AsyncThunkConfig
>("examination/delete", async (examinationId: string, thunkAPI) => {
  try {
    const response = await client.delete<ApiResponse<DeleteExamination>>(
      `${examinationURL}${examinationId}/`
    );

    dispatchNotification(
      "success",
      I18n.t("Snackbars.deleteExaminationSuccess")
    );

    return { ...response.data.results, id: examinationId };
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification(
        "error",
        getTranslatedBackendMessage(extractedError.message)
      );

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});
