import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
    ApiError,
    ApiResponse,
    AsyncThunkConfig,
    isAxiosError,
} from "../../../api/axiosConfig";
import { PsychologicalLab } from "../../../api/psychologicalLabApi";
import { psychologicalLabsURL, psychologicalLabsGrantedURL } from "../../../utils/paths";
import { dispatchNotification, getTranslatedBackendMessage } from "../../../utils/redux";
import { CreatePsychLabData, EditPsychologicalLabDataRequest } from "../../../components/Forms/types";
import { I18n } from "react-redux-i18n";

export const fetchPsychologicalLabs = createAsyncThunk<
    PsychologicalLab[],
    undefined,
    AsyncThunkConfig
>("psychologicalLabs/fetchPsychologicalLabs", async (_, thunkAPI) => {
    try {
        const response = await client.get<ApiResponse<PsychologicalLab[]>>(`${psychologicalLabsGrantedURL}`);

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message, true);

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});

export const createPsychLab = createAsyncThunk<
    PsychologicalLab,
    CreatePsychLabData,
    AsyncThunkConfig
>("psychologicalLabsCRUD/createPsychLab", async (data, thunkAPI,) => {
    try {
        const response = await client.post(psychologicalLabsURL, data);

        dispatchNotification("success", I18n.t("Snackbars.createPsychLabSuccess"));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification("error", extractedError.message, true);

            return thunkAPI.rejectWithValue(extractedError);
        }
    }
});

export const editPsychLab = createAsyncThunk<
    PsychologicalLab,
    EditPsychologicalLabDataRequest,
    AsyncThunkConfig
>("psychologicalLabsCRUD/editPsychLab", async (data, thunkAPI,) => {
    try {
        const response = await client.patch<ApiResponse<PsychologicalLab>>(
            `${psychologicalLabsURL}${data.id}/`,
            { ...data }
        );
        dispatchNotification("success", I18n.t("Snackbars.editPsychLabSuccess"));

        return response.data.results;
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;
            dispatchNotification("error", extractedError.message, true);

            return thunkAPI.rejectWithValue(extractedError);
        }
        dispatchNotification("error");
        throw err;
    }
});

export const deletePsychLab = createAsyncThunk<
    undefined,
    number,
    AsyncThunkConfig
>("psychologicalLabsCRUD/deletePsychLab", async (arg, thunkAPI) => {
    try {
        await client.delete(`${psychologicalLabsURL}${arg}/`);
    } catch (err) {
        if (isAxiosError(err) && err.response != null) {
            const extractedError = err.response.data as ApiError;

            dispatchNotification(
                "error",
                getTranslatedBackendMessage(extractedError.message)
            );

            return thunkAPI.rejectWithValue(extractedError);
        }

        dispatchNotification("error");
        throw err;
    }
});