import {
  ExtendedAndAnswer,
  ExtendedBoolAnswer,
  FieldQuestion,
  InterviewFields,
  InterviewSection,
  OccupationalMedicineParsedAnswers,
  ParsedAnswer,
  SubmissionData,
} from "./types";
import { HasObjectNull } from "../../../../utils";

export const generateOptions = (options: string[]) =>
  options.map((item: string, index: number) => ({
    value: index,
    label: item,
  }));

export const mapQuestions = (currentQuestion: ParsedAnswer) => {
  switch (currentQuestion && currentQuestion.type) {
    case "BOOL":
      return currentQuestion.answer || currentQuestion.answer === false
        ? String(currentQuestion.answer)
        : "";
    case "INT":
    case "XOR":
    case "TEXT":
      return currentQuestion.answer || currentQuestion.answer === 0
        ? String(currentQuestion.answer)
        : "";
    case "DUAL_INT":
      return {
        years: currentQuestion.answer1 ? String(currentQuestion.answer1) : "",
        months: currentQuestion.answer2 ? String(currentQuestion.answer2) : "",
      };
    case "AND":
      return currentQuestion.checkedIds || [];
    case "EXTENDED_AND":
      return {
        answer: currentQuestion.checkedIds || [],
        extended: currentQuestion.additionalInfo
          ? String(currentQuestion.additionalInfo)
          : "",
      };
    case "EXTENDED_BOOL":
      return {
        sectionSubheader: currentQuestion.sectionSubheader
          ? String(currentQuestion.sectionSubheader)
          : "",
        answer:
          currentQuestion.answer ||
            currentQuestion.answer === false ||
            currentQuestion.answer === 0
            ? String(currentQuestion.answer)
            : "",
        extended: currentQuestion.additionalInfo
          ? String(currentQuestion.additionalInfo)
          : "",
      };
  }
};

const getInitAdditionalInfoValue = (
  interviewSection: ParsedAnswer[]
): string | null => {
  const lastQuestion = interviewSection[interviewSection.length - 1];

  return lastQuestion && lastQuestion.type === "TEXT"
    ? lastQuestion.answer
      ? String(lastQuestion.answer)
      : ""
    : null;
};

export const handleInitialData = (interview: Partial<SubmissionData>) => {
  if (interview) {
    const allSections: InterviewSection[] = Object.keys(
      interview
    ) as InterviewSection[];

    const initialState: Partial<InterviewFields> = {};

    if (!HasObjectNull(interview)) {
      allSections.forEach((section) => {
        const interviewSection = interview[section];

        if (section === "occupationalMedicine" && interview[section]) {
          const mappedOccupationalMedicine = Object.entries(
            interviewSection as OccupationalMedicineParsedAnswers
          ).map((entry) => {
            const [subSection, answers] = entry;
            const questions = answers.map(mapQuestions) as FieldQuestion[];

            return [subSection, { question: questions }];
          });

          initialState[section] = Object.fromEntries(
            mappedOccupationalMedicine
          );
        } else if (!interviewSection) {
          initialState[section] = { question: [] };
        } else {
          const questions = (interviewSection as ParsedAnswer[]).map(
            mapQuestions
          ) as FieldQuestion[];

          const additionalInfoValue = getInitAdditionalInfoValue(
            interviewSection as ParsedAnswer[]
          );

          initialState[section] =
            additionalInfoValue !== null
              ? {
                question: questions,
                additionalInfo: additionalInfoValue,
              }
              : { question: questions };
        }
      });

      return initialState;
    }

    return Object.fromEntries(allSections.map((key) => [key, {}]));
  }
};

export const isDualIntType = (value: any): boolean => {
  return (
    Object.prototype.hasOwnProperty.call(
      value as Record<string, unknown>,
      "years"
    ) &&
    Object.prototype.hasOwnProperty.call(
      value as Record<string, unknown>,
      "months"
    )
  );
};

export const isExtendedBoolType = (value: any): boolean => {
  return (value as ExtendedBoolAnswer)?.answer !== undefined;
};

export const isExtendedAndType = (value: any): boolean => {
  return (value as ExtendedAndAnswer)?.answer !== undefined;
};

export const isStringNumberArray = (value: any): boolean => {
  return (
    value instanceof Array &&
    value.every(
      (element) => typeof element === "string" || typeof element === "number"
    )
  )
};
